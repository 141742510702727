import { useIsEditing } from "../editing/useIsEditing"
import { EmailSection } from "./EmailSection"
import { useProximityBox } from "../../reactor/Web/ProximityBox"
import { ColorStyles } from "../ui"
import { useHover } from "../hooks/useHover"
import { PickEditingTemplate } from "../editing/EditingTemplatePicker"
import { useContextualModal } from "../modal/Modal"
import { useObserveYProxy } from "../y/useObserveYProxy"
import { SelectionContext } from "../editing/SelectionContext"
import { GetType, SubstituteAndDiscriminate } from "../../reactor"
import { YTools } from "../y/YTools"
import { GetYProxyTarget } from "../y/YProxyTarget"
import { useContext } from "react"
import { SetEditableActions } from "../editing/EditableActions"

function AddNewSectionDivider({ sections, index }: { sections?: EmailSection[]; index: number }) {
    const { boxRef, isClosest } = useProximityBox()
    const { hover, hoverProps } = useHover()
    const backgroundColor = hover ? ColorStyles.primary[500] : ColorStyles["gray-warm"][300]

    const { modal, showModal, showingModal } = useContextualModal<EmailSection | undefined>()

    async function insertAbove() {
        const section = (await PickEditingTemplate("EmailSection", showModal)) as
            | EmailSection
            | undefined
        if (section) {
            sections?.splice(index, 0, section)
        }
    }

    const size = 24
    return (
        <div
            {...hoverProps}
            ref={boxRef}
            onClick={insertAbove}
            style={{
                height: 2,
                width: "100%",
                backgroundColor,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: isClosest ? 1 : 0,
                overflow: "visible",
                transition: "all 0.2s",
                cursor: "pointer",
                zIndex: showingModal ? undefined : isClosest ? 10001 : 10000,
            }}
        >
            {modal}
            <div
                onClick={insertAbove}
                style={{
                    position: "absolute",
                    top: -size / 2,
                    left: -size / 2,
                    width: size,
                    height: size,
                    borderRadius: size,
                    color: "white",
                    backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 2,
                    verticalAlign: "middle",
                    transition: "background-color 0.2s",
                }}
            >
                <div style={{ paddingBottom: 3 }}>+</div>
            </div>
        </div>
    )
}

/**
 * Renders vertically stacked sections in an email-friendly way.
 *
 * This includes using nested tables for consistent vertical stacking in all
 * mail clients. This component is intended to be used in an email template.
 *
 */
export function EmailSectionsVStack({ sections }: { sections: EmailSection[] }) {
    const editing = useIsEditing()

    useObserveYProxy(sections)

    return (
        <table role="presentation" width="100%" cellSpacing={0} cellPadding={0}>
            {sections.map((section, index) => (
                <tr key={section.id.valueOf()}>
                    <td>
                        <table role="presentation" width="100%" cellSpacing={0} cellPadding={0}>
                            <tr>
                                <td style={{ position: "relative" }}>
                                    {editing && (
                                        <AddNewSectionDivider sections={sections} index={index} />
                                    )}
                                    <EmailSectionView
                                        section={section}
                                        deleteThis={() => {
                                            sections.splice(index, 1)
                                        }}
                                    />
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            ))}
            {editing && (
                <tr key="last-divider">
                    <td style={{ position: "relative" }}>
                        <AddNewSectionDivider sections={sections} index={sections.length} />
                    </td>
                </tr>
            )}
        </table>
    )
}
/**
 * Renders a single email section.
 */
export function EmailSectionView(props: { section: EmailSection; deleteThis?: () => void }) {
    const editing = useIsEditing()
    const sc = useContext(SelectionContext)

    const Func = EmailSection.sections.get(props.section.type)
    if (!Func)
        return (
            <div>
                Section type not found: {props.section.type}, available sections are
                {Array.from(EmailSection.sections.keys()).join(", ")}
            </div>
        )

    if (editing && sc) {
        const comp = Func(props.section)
        const selected = sc.selectedObject === props.section
        return (
            <div
                style={{ outline: selected ? "1px solid #007bff" : "none" }}
                onClick={(e) => {
                    sc.setSelectedObject(
                        props.section,
                        SubstituteAndDiscriminate(
                            YTools.valueToJSON(GetYProxyTarget(props.section)),
                            GetType("EmailSection")
                        )
                    )
                    if (props.deleteThis)
                        SetEditableActions(props.section, {
                            deleteThis: props.deleteThis,
                        })
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                {comp}
            </div>
        )
    }

    return Func(props.section)
}
