import { useContext } from "react"
import { useSelectionContext } from "./SelectionContext"
import { GetTypeAlias, GetTypeProps, IsArrayType, Type } from "../../reactor/Types/Type"
import { ArrayContext } from "./EditableArray"
import { SubstituteAndDiscriminate } from "../../reactor/ReflectionInfo"
import { GetAncestors } from "./Ancestry"
import { prettyCamel } from "../../reactor/Helpers"
import { GetEditableActions } from "./EditableActions"
import { ReadonlyContext } from "../../studio/Views/ObjectContext"
import { RButton } from "../../studio/Views/Buttons"
import { ObjectView } from "../../studio/Views/ObjectView"
import { YEditor } from "../y/YEditor"
import { GetYProxyTarget } from "../y/YProxyTarget"
import { YTools } from "../y/YTools"

export function ObjectEditor({
    obj,
    type,
    putType,
    skipAncestors,
    declarations,
}: {
    obj: any
    type: Type
    putType?: Type
    skipAncestors?: number
    declarations?: string
}) {
    const resources = YEditor.editors
    const { setSelectedObject } = useSelectionContext()
    const { arr } = useContext(ArrayContext)
    const index = arr?.indexOf(obj)

    const target = GetYProxyTarget(obj)
    type = SubstituteAndDiscriminate(target ? YTools.valueToJSON(target) : obj, type)

    const icon = (typeof type === "object" && type.tags?.icon) || "box"

    const ancestors = GetAncestors(resources, obj).filter((x) => !IsArrayType(x.type))
    const isRoot = resources.some((r) => r.proxy === obj)
    const display = isRoot || ancestors.length > 0

    const rootResource = Array.from(resources).find((r) => r.proxy === obj)

    const titleProp = GetTypeProps(type).find((p) => p.tags?.title)
    const titleFromProp = titleProp ? obj[titleProp.name] : undefined

    let title = rootResource
        ? rootResource.res
        : typeof titleFromProp === "string"
          ? titleFromProp
          : prettyCamel((type && GetTypeAlias(type)) || "Item")

    if (title.startsWith("get")) title = title.substr(3)
    if (title.endsWith("section")) title = title.substr(0, title.length - 7)

    const actions = GetEditableActions(obj)

    const getterProps = GetTypeProps(type)
    const putterProps = putType ? GetTypeProps(putType) : getterProps
    if (!type || typeof type !== "object") throw new Error("This type cannot be edited")

    const editType = putType
        ? {
              ...type,
              props: getterProps.filter((p) => putterProps.some((pp) => pp.name === p.name)),
          }
        : type

    return (
        <ReadonlyContext.Provider value={{ mode: "edit" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                {ancestors.slice(skipAncestors ?? 0).map((a, i) => {
                    let name = a.name
                    if (name.startsWith("get")) name = name.substr(3)
                    const display = prettyCamel(name, true)
                    const icon = (typeof a.type === "object" && a.type.tags?.icon) || "box"
                    return (
                        <div
                            onClick={() => setSelectedObject(a.obj, a.type, a.putType)}
                            style={{
                                cursor: "pointer",
                                backgroundColor: "#eee",
                                padding: 8,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingLeft: 8 + 4 * i,
                                borderBottom: "1px solid #ddd",
                            }}
                        >
                            {icon && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginRight: 8,
                                    }}
                                >
                                    <i className={"fas fa-" + icon} />
                                </div>
                            )}
                            {display}
                        </div>
                    )
                })}
                {display ? (
                    <div
                        style={{
                            backgroundColor: "#eee",
                            padding: 8,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingLeft: 8 + 4 * ancestors.length,
                        }}
                    >
                        {icon && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <i className={"fas fa-" + icon} />
                            </div>
                        )}
                        {type && <span style={{ marginLeft: 8, marginRight: 32 }}>{title}</span>}
                        <div style={{ flex: 1 }} />
                        {actions?.saveAsTemplate && (
                            <RButton
                                icon="save"
                                tooltip="Save as template"
                                iconSize={14}
                                style={{ fontSize: 14 }}
                                onClick={actions.saveAsTemplate}
                            />
                        )}
                        {actions?.deleteThis && (
                            <RButton
                                icon="ui-trash-01"
                                tooltip="Delete"
                                iconSize={14}
                                style={{ fontSize: 14 }}
                                onClick={actions.deleteThis}
                            />
                        )}

                        {index !== -1 && (
                            <>
                                <RButton
                                    icon="ui-trash-01"
                                    iconSize={14}
                                    onClick={() => {
                                        arr.splice(index, 1)
                                    }}
                                ></RButton>
                            </>
                        )}
                        {index !== -1 && index > 0 && (
                            <RButton
                                icon="chevron-up"
                                iconSize={14}
                                onClick={() => {
                                    const [item] = arr.splice(index, 1)
                                    arr.splice(index - 1, 0, item)
                                }}
                            ></RButton>
                        )}
                        {index !== -1 && arr && index < arr.length - 1 && (
                            <RButton
                                icon="chevron-down"
                                iconSize={14}
                                onClick={() => {
                                    const [item] = arr.splice(index, 1)
                                    arr.splice(index + 1, 0, item)
                                }}
                            ></RButton>
                        )}
                    </div>
                ) : undefined}
                {display && (
                    <div
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            flex: 1,
                            marginBottom: 128,
                        }}
                    >
                        <ObjectView
                            obj={GetYProxyTarget(obj)}
                            buttons={[]}
                            property={{ name: "editable", type: editType }}
                            mode={"root"}
                            replaceObj={() => {}}
                            declarations={declarations}
                        />
                    </div>
                )}
            </div>
        </ReadonlyContext.Provider>
    )
}
