import { CSSProperties } from "react"
import { type SerializedStyles, css } from "@emotion/react"
import { type CSSInterpolation } from "@emotion/serialize"
import { Button } from "./Button"
import { ColorStyles } from "../../../packages/ui"
import { SidebarTheme } from "../../../studio/Views/SidebarTheme"

ColorStyles.primary = ColorStyles.teal
SidebarTheme.light = false
SidebarTheme.background = "linear-gradient(180deg, #132C35 0%, #071E26 100%)"

export const colors = {
    peach: "#FEA97C",
    darkGreen: "#0B3540",
    green: "#3c665b",
    mediumGreen: "#76D6BA",
    lightGreen: "#c7e6dd",
    semiDarkGreen: "#3c585f",
    foreground: "#2e2a27",
    white: "white",
    danger: "#b63",
    overlayGreen: "#0B354044",
    overlayGreenHover: "#0B354000",
    subtleHover: "#0B354011",
    primaryGreen: "#61A391",
    orange: "#F2994A",
    blue: "#53B1FE",
    red: "#EB5757",
    black: "#000",
}

export type Color = keyof typeof colors

export const headerStyle: CSSProperties = {
    fontSize: 36,
    fontWeight: "bold",
    color: "#0B3540",
}

export function Arrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.977"
            height="15.792"
            viewBox="0 0 15.977 15.792"
        >
            <path
                id="arrow-right"
                d="M7.778,34.711l-.706.706a.428.428,0,0,0,0,.605L12.6,41.555H.428A.428.428,0,0,0,0,41.983v1a.428.428,0,0,0,.428.428H12.6L7.072,48.941a.428.428,0,0,0,0,.605l.706.706a.428.428,0,0,0,.605,0l7.468-7.468a.428.428,0,0,0,0-.605L8.383,34.711A.428.428,0,0,0,7.778,34.711Z"
                transform="translate(0 -34.586)"
                fill="#6ec1a8"
            />
        </svg>
    )
}

export function CloseX() {
    return (
        <svg
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="18" x2="6" y1="6" y2="18" />
            <line x1="6" x2="18" y1="6" y2="18" />
        </svg>
    )
}

export const fontSizes = {
    body: 20,
    header: 36,
}

export function Title(props: { title: string; cta?: { text: string; onClick: () => void } }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "2rem",
                paddingBottom: "1rem",
                alignItems: "flex-end",
            }}
        >
            <div style={{ ...headerStyle, alignSelf: "flex-start" }}>{props.title}</div>

            {props.cta && (
                <div
                    style={{
                        marginLeft: 32,
                    }}
                >
                    <Button text={props.cta.text} onClick={props.cta.onClick} />
                </div>
            )}
        </div>
    )
}

/**
 * Each breakpoint is from the property value and up to the next, e.g. xs
 * screen is from 0-480px, and xl screen is from 1200 and up.
 */
const screenSizes = {
    xs: 0,
    sm: 480,
    md: 800,
    lg: 1024,
    xl: 1200,
}

type ScreenSize = keyof typeof screenSizes
type LimitType = "min" | "max"

/**
 * Helper function that returns a media query for a given screen size and limit type.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @returns A media query string
 */
export function mediaQuery(limitType: LimitType, screenSize: ScreenSize): string {
    switch (limitType) {
        case "min":
            return `@media (min-width: ${screenSizes[screenSize]}px)`
        case "max":
            const sizesKeysArr = Object.keys(screenSizes) as ScreenSize[]
            const sizeIndex = sizesKeysArr.indexOf(screenSize)

            if (sizeIndex + 1 === sizesKeysArr.length) {
                return "@media"
            }

            const nextSize = sizesKeysArr[sizeIndex + 1]

            return `@media (max-width: ${screenSizes[nextSize] - 1}px)`
        default:
            return ""
    }
}

/**
 * Helper function that returns a media query and styles for a given screen size.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @param styles The styles to apply to the given screen size limit
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveCss(
    limitType: LimitType,
    screenSize: ScreenSize,
    styles: CSSInterpolation
): SerializedStyles {
    return css({ [mediaQuery(limitType, screenSize)]: styles })
}

/**
 * Separate helper function that returns a media query and styles for screen.
 */
export function screenCss(styles: CSSInterpolation): SerializedStyles {
    return css({ "@media screen": styles })
}

/**
 * Separate helper function that returns a media query and styles for print.
 * @param styles The styles to apply to the printed version of the page
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function printCss(styles: CSSInterpolation): SerializedStyles {
    return css({ "@media print": styles })
}

const baseReset = css({
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: "100%",
    font: "inherit",
    verticalAlign: "baseline",
})

/**
 * Helper function that returns reset styles for a given HTML element, beyond the base reset
 * styles, but also removes default styles.
 *
 * @param element The HTML element to return reset styles for
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function resetStyles(element: keyof JSX.IntrinsicElements): SerializedStyles {
    switch (element) {
        case "button":
            return css(
                baseReset,
                css({
                    background: "none",
                    border: "none",
                    padding: 0,
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                })
            )
        case "ol":
        case "ul":
            return css(baseReset, css({ listStyle: "none" }))
        default:
            return baseReset
    }
}

export const hideOnPrint: SerializedStyles = css({ "@media print": { display: "none" } })

export const hideOnScreen: SerializedStyles = css({
    // Instead of `display: none`, to avoid issues with rendering charts on print.
    "@media screen": {
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0,
        zIndex: -1,
        // Account for studio bar and then some.
        height: "calc(100vh - 100px)",
        width: "100vw",
        overflow: "hidden",
    },
})
