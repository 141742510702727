import {
    ComponentProps,
    ElementType,
    Fragment,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from "react"
import { css, CSSObject, Global } from "@emotion/react"
import { EditableText } from "../../../packages/editing/EditableText"
import { useParam } from "../../../packages/ssr/useParam"
import { WidgetAPIView } from "../../../packages/widgets/WidgetAPIView"
import { Uuid } from "../../../reactor/Types/Primitives"
import {
    DenominatorUnit,
    GetOfferingReportDto,
    GetOfferingReportOfferingExecutiveSummaryDto,
    GetOfferingReportOfferingProcessQualitativeOverviewDto,
    GetOfferingReportOfferingProjectionSummaryDto,
    GetOfferingReportTextForAnalysisParameters1Dto,
    GetOfferingReportTextForExecutiveSummaryProjections1Dto,
    ProcessItem,
    useOfferingReport,
    useUserPrivileges,
} from "./client"
import { Chart } from "chart.js"
import { colors, hideOnPrint, hideOnScreen, printCss, screenCss } from "./styles"
import { Image, ImageToUrl } from "../../../reactor/Types/File"
import { WidgetView } from "../../../packages/widgets/WidgetView"
import { capitalizeFirstLetter, lowercaseFirstLetter } from "../../../reactor/Helpers"
import { ImpactPriority, ImpactScore, Offering, type ProcessItemBenchmark } from "../Offering"
import { useIsEditing } from "../../../packages/editing/useIsEditing"
import { Footer } from "./sections/Footer"
import { useChangeNotifications } from "../../../reactor/Web"
import { ColorStyles } from "../../../packages/ui"
import { HeaderSection } from "./sections/Header"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"
import Logo from "./assets/Logo"
import { ImpactOverviewTableParams, OfferingAnalysisChartParams } from "../OfferingAnalysis"
import { ProductionProjectionChartParams } from "../Offering.ProductionProjectionPage"
import { renderToString } from "react-dom/server"

Chart.defaults.font.family = "Jost"
Chart.defaults.font.size = 16

export function OfferingReportView() {
    const reportId = useParam("offeringId") as any as Uuid<"Offering">
    const { data: report, refresh } = useOfferingReport(reportId)
    const { data: privileges } = useUserPrivileges()
    const [impactCategory, setImpactCategory] = useState<string>("")

    useChangeNotifications(
        "Offerings",
        reportId.valueOf(),
        undefined, // Base layer
        refresh,
        !!privileges?.canEditWebsite
    )

    // Set to true if CSS Paged Media Module Level 3 like @top-left is supported. This is useful
    // for overriding content in top/bottom margins of document like URL etc.
    const [pagedMediaLevel3Supported, setPagedMediaLevel3Supported] = useState(false)

    function isPageAtRuleSupported(atRule: string) {
        const style = document.createElement("style")
        style.textContent = `@page { ${atRule} { content: "test"; } }`
        document.head.appendChild(style)

        const sheet = style.sheet

        let supported = false
        if (!sheet) return supported

        for (let i = 0; i < sheet.cssRules.length; i++) {
            const cssRule = sheet.cssRules.item(i)

            if (cssRule?.cssText.includes(atRule)) {
                supported = true
                break
            }
        }

        document.head.removeChild(style)
        return !!supported
    }

    useEffect(() => {
        setPagedMediaLevel3Supported(isPageAtRuleSupported("@top-left"))
    }, [])

    if (!report) return <></>

    const { offering, offeringUnit, impactCategories, textForExecutiveSummaryProjections } = report

    if (!impactCategory && impactCategories[0]) {
        setImpactCategory(impactCategories[0].id.valueOf())
    }

    return (
        <>
            <Global
                styles={css(
                    {
                        ":root": { fontSize: 18 },
                    },
                    printCss({ ":root": { fontSize: 16 } }),
                    css(
                        {
                            "@page": {
                                "@top-right": {
                                    marginRight: "-6mm",
                                    content: `url(
                                            'data:image/svg+xml;charset=UTF-8, ${encodeURIComponent(renderToString(<Logo color="black" width={24} trim />))}'
                                        )`,
                                },
                            },
                        },
                        css`
                            @page {
                                font-size: 12px;
                                margin: 20mm 16mm 20mm 16mm;

                                @bottom-right {
                                    margin-bottom: -8mm;
                                    margin-right: -8mm;
                                    content: counter(page);
                                }

                                @top-left {
                                    margin-top: -8mm;
                                    margin-left: -8mm;
                                    content: "${offering.name} – ${offering.secondaryName}";
                                }
                            }
                        `
                    )
                )}
            />
            <Cover front={true} image={report.coverFrontImage}>
                <div style={{ textAlign: "right", position: "absolute", top: 32, right: 32 }}>
                    <div>First published: {report.firstPublished}</div>
                    <div>Last modified: {report.firstPublished}</div>
                </div>
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        width: "100%",
                    })}
                >
                    <CoverText report={report} />
                    <div css={css({ display: "flex", justifyContent: "space-between" })}>
                        {!!report.coverLogoImage && (
                            <img
                                src={ImageToUrl(report.coverLogoImage)}
                                style={{ maxWidth: "33%" }}
                            />
                        )}
                        <div style={{ color: "#fff", alignSelf: "flex-end", textAlign: "right" }}>
                            <Heading level="3">Contact info:</Heading>
                            <div>{report.contactName}</div>
                            <div>{report.contactEmail}</div>
                        </div>
                    </div>
                </div>
            </Cover>
            <table style={{ width: "100%", overflow: "clip", position: "relative" }}>
                {/* Wrap in table to achieve a top right logo in every page for print version */}
                <thead css={css(hideOnScreen, pagedMediaLevel3Supported && { display: "none" })}>
                    <tr>
                        <td style={{ padding: 0, paddingBottom: 16 }} align="right">
                            <Logo color="darkGreen" width={32} />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr css={printCss({ display: "none" })}>
                        <td style={{ padding: 0 }}>
                            <div
                                css={css([
                                    hideOnScreen,
                                    {
                                        height: 90,
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    },
                                ])}
                            >
                                <div
                                    css={css(
                                        printCss({
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            height: 90,
                                        })
                                    )}
                                >
                                    <Logo
                                        color="white"
                                        width={48}
                                        css={css({
                                            zIndex: 2,
                                            position: "absolute",
                                            right: 20,
                                            top: 20,
                                        })}
                                    />
                                    <svg
                                        css={css({
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            zIndex: 1,
                                        })}
                                        width="100%"
                                        height="100%"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 10 10"
                                        preserveAspectRatio="none"
                                    >
                                        <rect
                                            x="0"
                                            y="0"
                                            width="10"
                                            height="10"
                                            fill={colors.darkGreen}
                                        />
                                    </svg>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: 0 }}>
                            <div style={{ padding: 0 }} css={printCss({ display: "none" })}>
                                <div
                                    css={css([
                                        hideOnScreen,
                                        {
                                            height: 90,
                                            display: "flex",
                                            justifyContent: "right",
                                            alignItems: "center",
                                        },
                                    ])}
                                >
                                    <div
                                        css={css(
                                            printCss({
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                left: 0,
                                                height: 90,
                                            })
                                        )}
                                    >
                                        <Logo
                                            color="white"
                                            width={48}
                                            css={css({
                                                zIndex: 2,
                                                position: "absolute",
                                                right: 20,
                                                top: 20,
                                            })}
                                        />
                                        <svg
                                            css={css({
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                left: 0,
                                                zIndex: 1,
                                            })}
                                            width="100%"
                                            height="100%"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 10 10"
                                            preserveAspectRatio="none"
                                        >
                                            <rect
                                                x="0"
                                                y="0"
                                                width="10"
                                                height="10"
                                                fill={colors.darkGreen}
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        position: "relative",
                                    }}
                                >
                                    <div css={css({ width: "100%" }, hideOnPrint)}>
                                        <HeaderSection size="compact" />
                                    </div>
                                </div>
                            </div>

                            <div
                                css={css(
                                    {
                                        maxWidth: 1152,
                                        margin: "0 auto",
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        table: {
                                            td: {
                                                whiteSpace: "normal",
                                                wordBreak: "break-all",
                                                fontSize: 14,
                                            },
                                        },
                                        canvas: {
                                            // Fixes stretched chart canvas on print
                                            width: "100% !important",
                                            height: "auto !important",
                                        },
                                        p: { fontFamily: "serif", marginBottom: 16, orphans: 2 },
                                    },
                                    printCss({
                                        maxWidth: "100%",
                                        padding: 0,
                                        display: "block",
                                        fontSize: "1rem",
                                        p: { fontSize: "1rem" },
                                        table: {
                                            td: {
                                                fontSize: "12px !important",
                                                div: {
                                                    fontSize: "12px !important",
                                                },
                                                p: {
                                                    fontSize: "12px !important",
                                                },
                                            },
                                        },
                                    })
                                )}
                            >
                                <CoverText report={report}></CoverText>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    {offering.image && (
                                        <img
                                            src={ImageToUrl(offering.image.valueOf())}
                                            alt="image"
                                            style={{
                                                flex: 1,
                                                flexDirection: "column",
                                                objectFit: "cover",
                                                maxWidth: "50%",
                                                marginTop: 40,
                                                marginBottom: 40,
                                            }}
                                        />
                                    )}
                                    <div
                                        style={{
                                            flex: 1,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {offering.overview && (
                                            <EditableText
                                                css={css(bodyTextCss, twoColumnsCss)}
                                                obj={offering}
                                                isMarkdown={true}
                                                prop="overview"
                                                placeholder="Overview goes here"
                                            />
                                        )}
                                    </div>
                                </div>
                                <Section css={hideOnScreen}>
                                    <Index report={report} />
                                </Section>
                                <Section id="analysis-parameters">
                                    <AnalysisParameters
                                        offering={offering}
                                        offeringUnit={offeringUnit}
                                        textForAnalysisParameters={report.textForAnalysisParameters}
                                    />
                                </Section>

                                {offering.executiveSummary && (
                                    <Section>
                                        <ExecutiveSummary summary={offering.executiveSummary} />
                                    </Section>
                                )}

                                <Section>
                                    <Heading level="2">Life Cycle Overview</Heading>
                                    <div css={css({ marginBottom: spacing.lg })}>
                                        <ReportSettingsTexts
                                            textObject={report.textForProcessOverview}
                                            diagram={report.processOverviewDiagram}
                                        />
                                    </div>
                                    <div css={css(figureCss, hideOnPrint)}>
                                        <WidgetAPIView
                                            name="OfferingReportProcessOverview"
                                            param={offering.id.valueOf()}
                                        />
                                    </div>
                                    <div css={hideOnScreen}>
                                        {offering.benchmarks.map((b, bi) => (
                                            <div key={b.name} css={css({ marginBottom: 32 })}>
                                                <Heading level="3">Benchmark: {b.name}</Heading>
                                                <div
                                                    css={
                                                        // Full width if only one impact
                                                        // category, no need for 2 col grid.
                                                        report.impactCategories.length === 1
                                                            ? undefined
                                                            : chartGridCss
                                                    }
                                                >
                                                    {report.impactCategories.map((ic) => (
                                                        <div
                                                            key={ic.id.valueOf()}
                                                            style={{ breakInside: "avoid" }}
                                                        >
                                                            <div css={css({ fontFamily: "Jost" })}>
                                                                Impact Category: {ic.name}
                                                            </div>
                                                            <OfferingAnalysisChartWidgetAPIView
                                                                offeringId={offering.id}
                                                                chart="impactBarChart"
                                                                benchmarkIndex={bi}
                                                                impactCategory={ic.id}
                                                                margin={0}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div css={figureCss}>
                                                    <ImpactOverviewTableWidgetAPIView
                                                        offeringId={offering.id}
                                                        benchmarkIndex={bi}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Section>

                                <ProcessOverview
                                    offering={offering}
                                    report={report}
                                    impactCategory={impactCategory}
                                    setImpactCategory={setImpactCategory}
                                    isLoggedIn={!!privileges?.isLoggedIn}
                                />

                                <Section>
                                    <Heading level="2">Impact Analysis</Heading>
                                    <Heading level="3">
                                        {
                                            report.textForScopeAggregation
                                                ?.outliningImpactActionHeader
                                        }
                                    </Heading>
                                    {report.textForScopeAggregation && (
                                        <div css={bodyTextCss}>
                                            <MarkdownView
                                                value={
                                                    report.textForScopeAggregation
                                                        .outliningImpactActionText
                                                }
                                            />
                                        </div>
                                    )}
                                    <div style={{ marginTop: 32 }} css={figureCss}>
                                        <div css={hideOnPrint}>
                                            <WidgetAPIView
                                                param={offering.id.valueOf()}
                                                name="ImpactByScopeProcessItemsChart"
                                            />
                                        </div>{" "}
                                        <div css={hideOnScreen}>
                                            <WidgetAPIView
                                                param={offering.id.valueOf()}
                                                name="ImpactByScopeProcessItemsChartPrint"
                                            />{" "}
                                        </div>
                                    </div>
                                </Section>

                                {!report.hideProjectionsSection && !!offering.executiveSummary && (
                                    <Section>
                                        <Projections
                                            offering={offering}
                                            summary={offering.executiveSummary}
                                            projectionSummary={
                                                report.hideProjectionsSummary
                                                    ? undefined
                                                    : offering.projectionSummary
                                            }
                                            textForExecutiveSummaryProjections={
                                                textForExecutiveSummaryProjections
                                            }
                                            hideOfferingProjectionChart={
                                                report.hideOfferingProjectionChart
                                            }
                                        />
                                    </Section>
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr css={hideOnPrint}>
                        <td style={{ padding: 0 }}>
                            <Footer />
                        </td>
                    </tr>
                    <tr css={hideOnScreen}>
                        <td style={{ padding: 0 }}>
                            <Cover back={true} image={report.coverBackImage}>
                                <div css={css({ alignSelf: "flex-end", color: "#fff" })}>
                                    {!!report.coverLogoImage && (
                                        <img
                                            src={ImageToUrl(report.coverLogoImage)}
                                            style={{ width: "33%" }}
                                        />
                                    )}
                                    <div style={{ marginTop: 24 }}>
                                        <div style={{ marginBottom: 8 }}>
                                            ClimatePoint AS, Universitetsgata 12, 0157 Oslo
                                        </div>
                                        <div style={{ fontWeight: 200 }}>
                                            Follow us on X | Follow us on LinkedIn
                                        </div>
                                    </div>
                                </div>
                            </Cover>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

const lightYellowBg = ColorStyles.yellow[100]
const lightOrangeBg = ColorStyles.orange[50]
const lightGreenBg = ColorStyles.green[100]
const lightBlueBg = ColorStyles.blue[100]

function Index(props: { report: GetOfferingReportDto }) {
    return (
        <div
            css={css(css`
                ol {
                    font-family: "serif";
                    counter-reset: item;
                    padding-left: 0;
                }
                li {
                    display: flex;
                    align-items: flex-start;
                }
                li:before {
                    content: counters(item, ".") " ";
                    counter-increment: item;
                    display: inline-block;
                    margin-right: 12px;
                }

                li > ol > li {
                    display: "inline-block";
                    margin-left: 12px;
                }

                ol > li > a::after {
                    content: leader(".") target-counter(attr(href), page);
                }
            `)}
        >
            <Heading level="2">Index</Heading>
            <ol>
                <li>
                    <a href="#analysis-parameters">Analysis Parameters</a>
                </li>
                {!!props.report.offering.executiveSummary && <li>Executive Summary</li>}
                <li>
                    <div>Life Cycle Overview</div>
                </li>
                <li>
                    <div>
                        Impact Analysis
                        <ol>
                            <li>Impact Aggregation per Scope</li>
                            <li>Impact Aggregation per Life Cycle Stage</li>
                        </ol>
                    </div>
                </li>
                {!props.report.hideProjectionsSection &&
                    !!props.report.offering.executiveSummary && <li>Projections</li>}
            </ol>
        </div>
    )
}

function AnalysisParameters({
    offering,
    offeringUnit,
    textForAnalysisParameters,
}: {
    offering: Offering
    offeringUnit: DenominatorUnit
    textForAnalysisParameters?: GetOfferingReportTextForAnalysisParameters1Dto
}) {
    const [expanded, setExpanded] = useState(false)

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
            })}
        >
            <Heading level="2" style={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                Analysis Parameters
            </Heading>
            <div
                // This is the expanded view that should always show on print, and only if expanded
                // on screen.
                css={css(
                    printCss({ display: "block" }),
                    expanded ? css({ display: "block" }) : css({ display: "none" })
                )}
            >
                <div css={twoColumnsCss}>
                    <Heading level="3">Goal and scope</Heading>
                    <Heading level="4">Functional Unit</Heading>
                    <WidgetView
                        value={{
                            type: "Item",
                            icon: "balance-scale",
                            primary:
                                capitalizeFirstLetter(offeringUnit.name) +
                                " of " +
                                lowercaseFirstLetter(offering.name),
                        }}
                    />
                    {!!offering.unitDescription && (
                        <div style={{ marginTop: 16 }}>
                            <EditableText
                                css={bodyTextCss}
                                isMarkdown={true}
                                obj={offering}
                                prop="unitDescription"
                            />
                        </div>
                    )}
                    <Heading level="4">Benchmark</Heading>
                    {offering.benchmarks.map((benchmark, bi) => (
                        <Fragment key={benchmark.name}>
                            <WidgetView
                                value={{
                                    type: "Item",
                                    icon: "balance-scale",
                                    color: "orange",
                                    primary: capitalizeFirstLetter(benchmark.name),
                                }}
                            />
                            {!!offering.benchmarks[bi].description && (
                                <div style={{ marginTop: 16 }}>
                                    <EditableText
                                        isMarkdown={true}
                                        css={bodyTextCss}
                                        obj={offering.benchmarks[bi]}
                                        prop="description"
                                    />
                                </div>
                            )}
                        </Fragment>
                    ))}

                    {!!offering.referenceFlow && (
                        <>
                            <Heading level="4">Reference flow</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="referenceFlow"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.goal && (
                        <>
                            <Heading level="4">Goal</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="goal"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.reasonForStudy && (
                        <>
                            <Heading level="4">Reason for study</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="reasonForStudy"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.audience && (
                        <>
                            <Heading level="4">Audience</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="audience"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    <Heading level="3">Scope</Heading>
                    {!!offering.systemBoundaryDescription && (
                        <>
                            <Heading level="4">System Boundary</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="systemBoundaryDescription"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.lifetimeDescription && (
                        <>
                            <Heading level="4">Lifetime</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="lifetimeDescription"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    <Heading level="3">Methodology</Heading>
                    {!!textForAnalysisParameters && (
                        <>
                            <Heading level="4">
                                {textForAnalysisParameters.outliningImpactActionHeader}
                            </Heading>
                            <div css={bodyTextCss}>
                                <MarkdownView
                                    value={textForAnalysisParameters.outliningImpactActionText}
                                />
                            </div>
                        </>
                    )}
                    {!!offering.cutOffCriteria && (
                        <>
                            <Heading level="4">Cut-off Criteria</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="cutOffCriteria"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.allocationProcedure && (
                        <>
                            <Heading level="4">Allocation procedure</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="allocationProcedure"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.impactAssessmentAndImpactCategories && (
                        <>
                            <Heading level="4">Impact assessment and impact categories</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="impactAssessmentAndImpactCategories"
                                css={bodyTextCss}
                            />
                        </>
                    )}

                    <Heading level="3">Data Quality</Heading>
                    {!!offering.technologyCoverage && (
                        <>
                            <Heading level="4">Technology coverage</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="technologyCoverage"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.temporalCoverage && (
                        <>
                            <Heading level="4">Temporal coverage</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="temporalCoverage"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.geographicalBoundary && (
                        <>
                            <Heading level="4">Geographical boundary</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="geographicalBoundary"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.exclusions && (
                        <>
                            <Heading level="4">Assumptions and limitations</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="exclusions"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {!!offering.externalReview && (
                        <>
                            <Heading level="4">External review</Heading>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="externalReview"
                                css={bodyTextCss}
                            />
                        </>
                    )}
                    {offering.systemBoundaryDiagram ? (
                        <>
                            <Heading level="4">System Boundary Diagram</Heading>
                            <img
                                src={ImageToUrl(offering.systemBoundaryDiagram)}
                                alt="image"
                                style={{
                                    marginTop: 32,
                                    marginBottom: 32,
                                    objectPosition: "center",
                                    height: 300,
                                    objectFit: "contain",
                                }}
                            />
                        </>
                    ) : null}
                </div>
            </div>
            <div
                // This is the collapsed view.
                onClick={() => setExpanded(!expanded)}
                css={css(
                    printCss({ display: "none" }),
                    expanded
                        ? { display: "none" }
                        : {
                              display: "flex",
                              flexDirection: offering.benchmarks.length < 3 ? "row" : "column",
                              justifyContent: "center",
                              margin: "0 32px",
                              paddingBottom: "32px",
                          }
                )}
            >
                <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                    <Heading
                        level="3"
                        style={{
                            display: "flex",
                            justifyContent: offering.benchmarks.length < 3 ? "unset" : "center",
                            marginBottom: 12,
                        }}
                    >
                        Functional Unit
                    </Heading>
                    <WidgetView
                        value={{
                            type: "Item",
                            icon: "balance-scale",
                            primary:
                                capitalizeFirstLetter(offeringUnit.name) +
                                " of " +
                                lowercaseFirstLetter(offering.name),
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

function ExecutiveSummary({ summary }: { summary: GetOfferingReportOfferingExecutiveSummaryDto }) {
    return (
        <div style={{ marginTop: 32, marginBottom: 32, display: "flex", flexDirection: "column" }}>
            <Heading level="2">Executive Summary</Heading>
            <div css={css(twoColumnsCss)}>
                {!!summary.keyRevelations && (
                    <div css={articleSectionCss}>
                        <Heading level="3">Key revelations</Heading>
                        <EditableText
                            css={bodyTextCss}
                            style={{ display: "flow-root" }}
                            isMarkdown={true}
                            obj={summary}
                            prop="keyRevelations"
                        />
                    </div>
                )}
                {!!summary.insightsToImpactStrategy && (
                    <div css={articleSectionCss}>
                        <Heading level="3">Insights to Impact Strategy</Heading>
                        <EditableText
                            css={bodyTextCss}
                            style={{ display: "flow-root" }}
                            obj={summary}
                            isMarkdown={true}
                            prop="insightsToImpactStrategy"
                        />
                    </div>
                )}
                {!!summary.potentialChallenges && (
                    <div css={articleSectionCss}>
                        <Heading level="3">Potential Challenges</Heading>
                        <EditableText
                            css={bodyTextCss}
                            style={{ display: "flow-root" }}
                            obj={summary}
                            isMarkdown={true}
                            prop="potentialChallenges"
                        />
                    </div>
                )}
                {!!summary.possibleRebounds && (
                    <div css={articleSectionCss}>
                        <Heading level="3">Possible Rebounds</Heading>
                        <EditableText
                            css={bodyTextCss}
                            style={{ display: "flow-root" }}
                            obj={summary}
                            isMarkdown={true}
                            prop="possibleRebounds"
                        />
                    </div>
                )}
                {!!summary.climateValueProposition && (
                    <div css={articleSectionCss}>
                        <Heading level="3">Climate Value Proposition</Heading>
                        <EditableText
                            css={bodyTextCss}
                            style={{ display: "flow-root" }}
                            obj={summary}
                            isMarkdown={true}
                            prop="climateValueProposition"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

function ProcessOverview({
    offering,
    report,
    impactCategory,
    setImpactCategory,
    isLoggedIn,
}: {
    offering: Offering
    report: GetOfferingReportDto
    impactCategory: string
    setImpactCategory: React.Dispatch<React.SetStateAction<string>>
    isLoggedIn: boolean
}) {
    return (
        <Section>
            <Heading level="2">Process Overview</Heading>
            <ReportSettingsTexts
                textObject={report.textForScopeAllocationAnalysis}
                diagram={report.scopeAllocationDiagram}
            />
            {report.impactCategories.length > 1 && (
                <div
                    css={css(
                        {
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontWeight: "bold",
                            marginTop: 16,
                        },
                        hideOnPrint
                    )}
                >
                    <div style={{ marginRight: 16 }}>Impact factor</div>
                    <select
                        value={impactCategory}
                        onChange={(e) => setImpactCategory(e.target.value)}
                        style={{
                            padding: 8,
                            borderRadius: 8,
                            borderColor: "#ccc",
                        }}
                    >
                        {report.impactCategories.map((c) => (
                            <option key={c.id.valueOf()} value={c.id.valueOf()}>
                                {c.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div css={css(printCss({ display: "none" }))}>{ProcessColumnHeader()}</div>
            <div
                css={css(printCss({ display: "none" }))}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        height: 2,
                        backgroundColor: colors.darkGreen,
                    }}
                />
            </div>
            {impactCategory &&
                offering.process.map((p, i) => {
                    return (
                        <ProcessItemView
                            key={p.name}
                            index={i}
                            processItem={p}
                            unit={report.functionalUnit}
                            report={report}
                            setImpactCategory={setImpactCategory}
                            impactCategory={impactCategory}
                            isLoggedIn={isLoggedIn}
                        />
                    )
                })}
        </Section>
    )
}

export function Tabs({ tabs }: { tabs: { [title: string]: JSX.Element } }) {
    const [current, setCurrent] = useState(Object.keys(tabs)[0])

    return (
        <>
            <div style={{ marginTop: 64 }} css={css(hideOnPrint)}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginBottom: 32,
                    }}
                >
                    {Object.keys(tabs).map((title, i) => (
                        <div
                            key={title}
                            style={{
                                flex: 1,
                                textAlign: "center",
                                padding: 8,
                                backgroundColor:
                                    current === title
                                        ? ColorStyles.primary[700]
                                        : ColorStyles.primary[100],
                                color: current === title ? "white" : ColorStyles.primary[800],
                                borderRadius: 8,
                                marginLeft: i > 0 ? 16 : 0,
                                userSelect: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => setCurrent(title)}
                        >
                            {title}
                        </div>
                    ))}
                </div>
                {tabs[current]}
            </div>
            {/** A simplified rendering for print only */}
            <div css={css(hideOnScreen)}>
                {Object.entries(tabs).map(([title, tab]) => (
                    <div
                        key={title}
                        css={css({ marginBottom: 50, ":last-child": { marginBottom: 0 } })}
                    >
                        <div>{tab}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export function impactScoreColor(s: ImpactScore) {
    switch (s) {
        case "Aligned":
            return colors.primaryGreen
        case "Negative":
            return colors.red
        case "Potential":
            return colors.orange
        case "Rebound":
            return colors.orange
        case "None":
            return colors.green
    }
}
export function impactPriorityColor(s: ImpactPriority) {
    switch (s) {
        case "High":
            return colors.red
        case "Medium":
            return colors.orange
        case "Low":
            return colors.primaryGreen
        case "None":
            return colors.green
    }
}

function ReportSettingsTexts({ textObject, diagram }: { textObject?: any; diagram?: Image }) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {diagram && (
                <img
                    src={ImageToUrl(diagram)}
                    alt="image"
                    style={{
                        marginBottom: 32,
                        objectPosition: "center",
                        height: 300,
                        objectFit: "contain",
                    }}
                />
            )}
            <div css={twoColumnsCss}>
                <Heading level="3">{textObject?.backgroundInformationHeader}</Heading>
                {textObject && <MarkdownView value={textObject.backgroundInformationText} />}
                <Heading level="3">{textObject?.outliningImpactActionHeader}</Heading>
                {textObject && <MarkdownView value={textObject.outliningImpactActionText} />}
            </div>
        </div>
    )
}

function ProcessColumnHeader() {
    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 8,
                    height: 64,
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: 64,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        fontSize: 26,
                    }}
                >
                    <div
                        style={{
                            color: colors.darkGreen,
                            fontWeight: "bold",
                        }}
                    >
                        #
                    </div>
                </div>
                <div
                    style={{
                        fontSize: 24,
                        padding: 16,
                        color: colors.darkGreen,
                        flex: 3,
                        fontWeight: "bold",
                    }}
                >
                    Process item
                </div>
                <div
                    style={{
                        flex: 1.5,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    {"Scope"}
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    Score
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: 60,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    Priority
                </div>
            </div>
        </div>
    )
}

function ProcessItemView({
    processItem: pi,
    impactCategory,
    report,
    index,
    setImpactCategory,
    isLoggedIn,
}: {
    processItem: ProcessItem
    impactCategory: string
    report: GetOfferingReportDto
    index: number
    setImpactCategory: React.Dispatch<React.SetStateAction<string>>
    unit: DenominatorUnit | undefined
    isLoggedIn: boolean | undefined
}) {
    const piName = pi.shortName ?? pi.name
    const [expanded, setExpanded] = useState(false)
    const [benchmarkName, setBenchmarkName] = useState(piName)
    const editing = useIsEditing()

    const benchmark = useMemo(
        () => pi.benchmark.find((b) => b.name === benchmarkName),
        [benchmarkName, pi.benchmark]
    )

    function renderScore(score: ImpactScore | ImpactPriority, type: "score" | "priority") {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        width: 9,
                        height: 9,
                        borderRadius: 6,
                        backgroundColor:
                            type === "score"
                                ? impactScoreColor(score as ImpactScore)
                                : impactPriorityColor(score as ImpactPriority),
                        marginRight: 8,
                    }}
                />
                {score}
            </div>
        )
    }

    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
                breakInside: "avoid",
            }}
        >
            <div
                onClick={() => setExpanded(!expanded)}
                css={css(
                    {
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#EEEFF1",
                        alignItems: "center",
                        borderRadius: 8,
                        height: 64,
                        cursor: "pointer",
                    },
                    printCss({
                        flexDirection: "column",
                        alignItems: "flex-start",
                        height: "auto",
                        margin: 0,
                        backgroundColor: "transparent",
                    })
                )}
            >
                <div
                    css={css(
                        {
                            backgroundColor:
                                pi.parentScope === "Scope 1"
                                    ? colors.peach
                                    : pi.parentScope === "Scope 2"
                                      ? colors.darkGreen
                                      : colors.primaryGreen,
                            height: "100%",
                            width: 64,
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: 26,
                        },
                        printCss({
                            display: "none",
                        })
                    )}
                >
                    {index + 1}
                </div>
                <EditableText
                    obj={pi}
                    prop="name"
                    css={css(
                        screenCss({ color: colors.darkGreen }),
                        { fontSize: 26, padding: 16, flex: 3 },
                        printCss({
                            fontSize: 20,
                            padding: 0,
                            fontWeight: "bold",
                            ":before": {
                                content: `"${index + 1}. "`,
                            },
                        })
                    )}
                />
                <div
                    style={{ flex: 1.5 }}
                    css={css(printCss({ ":before": { content: '"Scope: "' } }))}
                >
                    {pi.recursiveScopeAnalysis
                        ? "Multiple Scopes"
                        : `${pi.parentScope} ${pi.childScope}`}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    css={css(printCss({ ":before": { content: '"Score: "', marginRight: 4 } }))}
                >
                    {renderScore(pi.qualitativeOverview.impactScore, "score")}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    css={css(printCss({ ":before": { content: '"Priority: "', marginRight: 4 } }))}
                >
                    {renderScore(pi.qualitativeOverview.priority, "priority")}
                </div>
                <i
                    className={`fas fa-chevron-${expanded ? "up" : "down"}`}
                    style={{
                        flex: 0.15,
                        paddingRight: 32,
                        textAlign: "center",
                    }}
                    css={css(hideOnPrint)}
                />
            </div>
            <div css={css(!expanded && hideOnScreen)}>
                <>
                    <QualitativeOverviewImpact
                        qualitativeOverview={pi.qualitativeOverview}
                        editing={editing}
                    />
                    {isLoggedIn ? (
                        pi.quantitativeAnalysisVisible && (
                            <>
                                <Heading level="3">Quantitative Overview</Heading>
                                <div>
                                    <Heading level="4">Quantification Analysis</Heading>
                                    <EditableText
                                        obj={pi}
                                        css={bodyTextCss}
                                        prop="quantificationAnalysis"
                                        defaultText="Quantification analysis goes here."
                                        isMarkdown={true}
                                    />
                                </div>
                                {editing || pi.additionalInsights ? (
                                    <div style={{ marginTop: 16 }}>
                                        <Heading level="4">Additional Insights</Heading>
                                        <EditableText
                                            obj={pi}
                                            css={bodyTextCss}
                                            prop="additionalInsights"
                                            defaultText="Additional insights goes here."
                                            isMarkdown={true}
                                        />
                                    </div>
                                ) : undefined}
                                <div style={{ marginTop: 16, marginBottom: 48 }} css={figureCss}>
                                    <WidgetAPIView
                                        name="SubprocessImpact"
                                        param={`${report.offering.id}:${pi.id.valueOf()}:${impactCategory}`}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginBottom: 48,
                                    }}
                                >
                                    <div
                                        css={css(
                                            {
                                                marginTop: 32,
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                            },
                                            hideOnPrint
                                        )}
                                    >
                                        <div style={{ marginRight: 16 }}>Impact factor</div>
                                        <select
                                            value={impactCategory}
                                            onChange={(e) => setImpactCategory(e.target.value)}
                                            style={{
                                                padding: 8,
                                                borderRadius: 8,
                                                borderColor: "#ccc",
                                            }}
                                        >
                                            {report.impactCategories.map((c) => (
                                                <option key={c.id.valueOf()} value={c.id.valueOf()}>
                                                    {c.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {pi.displaySubProcess && pi.offering ? (
                                    <>
                                        <div
                                            css={css(
                                                {
                                                    backgroundColor: benchmark
                                                        ? lightYellowBg
                                                        : lightOrangeBg,
                                                    padding: 24,
                                                    marginTop: 16,
                                                    borderRadius: 8,
                                                },
                                                printCss({
                                                    backgroundColor: "transparent",
                                                    padding: 0,
                                                })
                                            )}
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    marginBottom: 16,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Heading level="3">Quantified sub-process</Heading>
                                                <select
                                                    css={hideOnPrint}
                                                    value={benchmarkName}
                                                    onChange={(e) =>
                                                        setBenchmarkName(e.target.value)
                                                    }
                                                    style={{
                                                        padding: 8,
                                                        borderRadius: 8,
                                                        borderColor: "#ccc",
                                                    }}
                                                >
                                                    <option key={piName} value={piName}>
                                                        {piName}
                                                    </option>
                                                    {pi.benchmark.map(
                                                        (c) =>
                                                            c.offering && (
                                                                <option key={c.name} value={c.name}>
                                                                    {c.name}
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                            <EditableText
                                                obj={pi}
                                                isMarkdown={true}
                                                prop="subProcessNotes"
                                                placeholder="Notes go here"
                                                css={bodyTextCss}
                                            />
                                            <div css={hideOnScreen}>
                                                <div>Benchmark: {piName}</div>
                                                <div css={figureCss}>
                                                    <WidgetAPIView
                                                        name="ProcessImpactBarChart"
                                                        param={`${report.offering.id.valueOf()}:${pi.id.valueOf()}:${impactCategory}:${piName}`}
                                                    />
                                                </div>
                                                {pi.benchmark.map(
                                                    (pib) =>
                                                        pib.offering && (
                                                            <div
                                                                key={pib.name}
                                                                style={{ breakInside: "avoid" }}
                                                            >
                                                                <div>Benchmark: {pib.name}</div>
                                                                <div css={figureCss}>
                                                                    <WidgetAPIView
                                                                        name="ProcessImpactBarChart"
                                                                        param={`${report.offering.id.valueOf()}:${pi.id.valueOf()}:${impactCategory}:${pib.name}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                )}
                                            </div>
                                            <div css={css(figureCss, hideOnPrint)}>
                                                <WidgetAPIView
                                                    name="ProcessImpactBarChart"
                                                    param={`${report.offering.id.valueOf()}:${pi.id.valueOf()}:${impactCategory}:${benchmarkName}`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : undefined}
                                <PathwayGraphs
                                    pi={benchmark || pi}
                                    impactCategory={impactCategory}
                                />
                            </>
                        )
                    ) : (
                        <div
                            style={{
                                backgroundColor: lightBlueBg,
                                padding: 32,
                                marginTop: 16,
                                borderRadius: 8,
                            }}
                        >
                            <div style={{ fontWeight: "bold" }}>
                                User account login required to view quantification analysis.
                            </div>
                        </div>
                    )}
                    {pi.qualitativeOverview.discussion && (
                        <div
                            css={css(
                                {
                                    backgroundColor: lightGreenBg,
                                    padding: 24,
                                    margin: "16px 0",
                                    borderRadius: 8,
                                    "& p:last-of-type": {
                                        marginBottom: 0,
                                    },
                                },
                                printCss({
                                    padding: 0,
                                    margin: 0,
                                    backgroundColor: "transparent",
                                })
                            )}
                        >
                            <Heading level="3">Discussion</Heading>
                            <EditableText
                                css={bodyTextCss}
                                obj={pi.qualitativeOverview}
                                prop="discussion"
                                isMarkdown={true}
                                defaultText="Discussion goes here"
                            />
                        </div>
                    )}
                </>
            </div>
        </div>
    )
}

type QualitativeOverviewImpactProps = {
    qualitativeOverview: GetOfferingReportOfferingProcessQualitativeOverviewDto
    editing: boolean
}

function QualitativeOverviewImpact({
    qualitativeOverview,
    editing,
}: QualitativeOverviewImpactProps) {
    return (
        <>
            <div
                css={css(
                    {
                        backgroundColor: lightYellowBg,
                        padding: 24,
                        margin: "16px 0",
                        borderRadius: 8,
                        "& p:last-of-type": {
                            marginBottom: 0,
                        },
                    },
                    printCss({
                        padding: 0,
                        backgroundColor: "transparent",
                    })
                )}
            >
                <div css={articleSectionCss}>
                    <Heading level="3">Process Description</Heading>
                    <EditableText
                        css={bodyTextCss}
                        obj={qualitativeOverview}
                        isMarkdown={true}
                        prop="processDescription"
                        defaultText="Process Description goes here"
                    />
                </div>
            </div>
            {editing || qualitativeOverview.benchmarks ? (
                <div css={articleSectionCss}>
                    <Heading level="3">Benchmark</Heading>
                    <EditableText
                        css={bodyTextCss}
                        obj={qualitativeOverview}
                        prop="benchmarks"
                        isMarkdown={true}
                        defaultText="Benchmarks goes here"
                    />
                </div>
            ) : undefined}

            {editing || qualitativeOverview.methodology ? (
                <div css={articleSectionCss}>
                    <Heading level="3">Methodology</Heading>
                    <EditableText
                        css={bodyTextCss}
                        obj={qualitativeOverview}
                        isMarkdown={true}
                        prop="methodology"
                        defaultText="Methodology goes here"
                    />
                </div>
            ) : undefined}
        </>
    )
}

type PathwayGraphsProps = {
    pi: ProcessItem | ProcessItemBenchmark
    impactCategory: string
}

function PathwayGraphs({ pi, impactCategory }: PathwayGraphsProps) {
    return (
        <>
            {("showBaselineImpactPathway" in pi ? pi.showBaselineImpactPathway : true) &&
                pi.baselineImpactPathway && (
                    <div
                        style={{
                            backgroundColor: lightBlueBg,
                            padding: 32,
                            marginTop: 16,
                            borderRadius: 8,
                            breakInside: "avoid",
                        }}
                    >
                        <div style={{ fontWeight: "bold", marginBottom: 16 }}>
                            Baseline scenario (%)
                        </div>
                        {"baselineImpactPathwayDescription" in pi && (
                            <EditableText
                                obj={pi}
                                isMarkdown={true}
                                prop="baselineImpactPathwayDescription"
                                css={bodyTextCss}
                            />
                        )}
                        <WidgetAPIView
                            name="PathwayChart"
                            param={`${pi.baselineImpactPathway.valueOf()}:${impactCategory}`}
                        />
                    </div>
                )}
            {("showAmountPathway" in pi ? pi.showAmountPathway : true) && pi.amountPathway && (
                <div
                    style={{
                        backgroundColor: lightBlueBg,
                        padding: 32,
                        marginTop: 16,
                        borderRadius: 8,
                    }}
                >
                    <div style={{ fontWeight: "bold", marginBottom: 16 }}>Amount pathway (%)</div>
                    {"amountPathwayDescription" in pi && (
                        <EditableText
                            obj={pi}
                            isMarkdown={true}
                            prop="amountPathwayDescription"
                            css={bodyTextCss}
                        />
                    )}
                    <WidgetAPIView
                        name="PathwayChart"
                        param={`${pi.amountPathway.valueOf()}:${impactCategory}`}
                    />
                </div>
            )}
        </>
    )
}

function Cover(props: { image?: Image; children?: ReactNode; front?: boolean; back?: boolean }) {
    return (
        <div
            css={css(hideOnScreen, {
                pageBreakAfter: props.front ? "always" : "auto",
                pageBreakBefore: props.back ? "always" : "auto",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
                position: "relative",
            })}
        >
            {!!props.image && (
                <img
                    src={ImageToUrl(props.image)}
                    css={css({
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        objectFit: "cover",
                    })}
                />
            )}
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 10,
                    padding: "60px",
                    display: "flex",
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

function CoverText({ report }: { report: GetOfferingReportDto }) {
    const { offering } = report
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div style={{ marginTop: 32, fontSize: 20, textAlign: "center" }}>
                {offering.analysisType}
            </div>
            <div
                style={{
                    marginTop: 8,
                    marginBottom: 8,
                    fontSize: 36,
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                {offering.name}
            </div>
            <div style={{ fontSize: 24, textAlign: "center" }}>{offering.secondaryName}</div>
            <div style={{ margin: 20, fontSize: 20, textAlign: "center" }}>
                {offering.typeOfProductOrService && offering.typeOfProductOrService + " Solution"}
            </div>
            <div
                style={{
                    marginTop: 32,
                    marginBottom: 5,
                    fontSize: 16,
                    fontWeight: "normal",
                    color: "#888",
                    textAlign: "center",
                }}
            >
                <a
                    onClick={() => {
                        if (report.account?.slug) {
                            window.open(`/accounts/${report.account.slug.toLocaleLowerCase()}`)
                        } else if (report.account) {
                            window.open(`/accounts/${report.account.id.toLocaleLowerCase()}`)
                        }
                    }}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <EditableText
                        obj={report.offering}
                        prop="attributionPrefix"
                        defaultText="A solution by"
                    />

                    <div
                        style={{
                            cursor: "pointer",
                            marginLeft: 4,
                            textDecoration: "underline",
                            color: "#61A391",
                            fontWeight: "500",
                        }}
                    >
                        {report.account?.name}
                    </div>
                </a>
            </div>
        </div>
    )
}

function Projections({
    offering,
    summary,
    projectionSummary,
    textForExecutiveSummaryProjections,
    hideOfferingProjectionChart,
}: {
    offering: Offering
    summary: GetOfferingReportOfferingExecutiveSummaryDto
    projectionSummary?: GetOfferingReportOfferingProjectionSummaryDto
    textForExecutiveSummaryProjections?: GetOfferingReportTextForExecutiveSummaryProjections1Dto
    hideOfferingProjectionChart?: boolean
}) {
    return (
        <div>
            <Heading level="2">Projections</Heading>
            <Heading level="3">How to read this section</Heading>
            <ul
                css={css(bodyTextCss, {
                    paddingBottom: 40,
                })}
            >
                <li>
                    <MarkdownView
                        value={`**Offering projections**: ${summary.offeringProjections}`}
                    />
                </li>
                <li>
                    <MarkdownView
                        value={[
                            `**${textForExecutiveSummaryProjections?.annualProjections.header ?? "Annual Emissions"}:**`,
                            textForExecutiveSummaryProjections?.annualProjections.text,
                            projectionSummary?.annualEmissions,
                        ].join(" ")}
                    />
                </li>
                <li>
                    <MarkdownView
                        value={[
                            `**${textForExecutiveSummaryProjections?.cumulativeProjections.header ?? "Cumulative Emissions"}:**`,
                            textForExecutiveSummaryProjections?.cumulativeProjections.text,
                            projectionSummary?.cumulativeEmissions,
                        ].join(" ")}
                    />
                </li>
                <li>
                    <MarkdownView
                        value={[
                            `**${textForExecutiveSummaryProjections?.reachingTheClimatePoint.header ?? "Reaching the ClimatePoint"}:**`,
                            textForExecutiveSummaryProjections?.reachingTheClimatePoint.text,
                            projectionSummary?.reachingTheClimatePoint,
                        ].join(" ")}
                    />
                </li>
            </ul>
            <div css={[hideOnPrint, css({ h3: headingLevelsCss["3"] })]}>
                <WidgetAPIView param={offering.id.valueOf()} name="ProductionProjectionReport" />
            </div>
            <div css={hideOnScreen}>
                {offering.scenarios.map((s, si) => (
                    <div key={s.name}>
                        {offering.benchmarks.map((b, bi) => (
                            <div
                                key={`${s.name}${b.name}`}
                                css={css({
                                    breakInside: "avoid",
                                    // Using margin-bottom will collapse.
                                    paddingBottom: spacing.lg,
                                })}
                            >
                                <Heading level="3">
                                    Scenario {si + 1}: {s.name}
                                </Heading>
                                <div style={{ marginBottom: 24 }} css={bodyTextCss}>
                                    <MarkdownView value={s.description} />
                                </div>
                                <Heading level="3">
                                    Benchmark {bi + 1}: {b.name}
                                </Heading>
                                <div style={{ marginBottom: 24 }} css={bodyTextCss}>
                                    <MarkdownView value={b.description} />
                                </div>
                                <div css={chartGridCss}>
                                    <ProductionProjectionChartWidgetAPIView
                                        key={`${b.name}${bi}productionChart`}
                                        offeringId={offering.id}
                                        benchmarkIndex={bi}
                                        scenarioId={s.id}
                                        chart="productionChart"
                                        margin={0}
                                    />
                                    <ProductionProjectionChartWidgetAPIView
                                        key={`${b.name}${bi}impactChart`}
                                        offeringId={offering.id}
                                        benchmarkIndex={bi}
                                        scenarioId={s.id}
                                        chart="impactChart"
                                        margin={0}
                                    />
                                    <ProductionProjectionChartWidgetAPIView
                                        key={`${b.name}${bi}cumulativeImpactChart`}
                                        offeringId={offering.id}
                                        benchmarkIndex={bi}
                                        scenarioId={s.id}
                                        chart="cumulativeImpactChart"
                                        margin={0}
                                    />
                                    <ProductionProjectionChartWidgetAPIView
                                        key={`${b.name}${bi}amortizedImpactChart`}
                                        offeringId={offering.id}
                                        benchmarkIndex={bi}
                                        scenarioId={s.id}
                                        chart="amortizedImpactChart"
                                        margin={0}
                                    />
                                </div>
                            </div>
                        ))}
                        {!!s.discussion && (
                            <div style={{ paddingBottom: 32 }}>
                                <div css={css(coloredBoxCss(lightGreenBg))}>
                                    <Heading level="4">Discussion</Heading>
                                    <EditableText isMarkdown={true} obj={s} prop="discussion" />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

const sectionCss = css(
    screenCss({
        marginTop: 64,
        marginBottom: 64,
        overflowY: "clip",
    }),
    printCss({
        breakInside: "avoid",
        breakBefore: "page",
    })
)

function coloredBoxCss(bgColor: string) {
    return css(
        { backgroundColor: bgColor, padding: 24 },
        printCss({ padding: 0, backgroundColor: "transparent" })
    )
}

const headingLevelsCss: Record<string, CSSObject> = {
    "1": { fontSize: "3rem", marginTop: 32, fontWeight: "bold" },
    "2": {
        textAlign: "center",
        fontSize: "2rem",
        fontWeight: 600,
        marginBottom: 32,
    },
    "3": {
        fontSize: "1.1rem",
        fontWeight: 600,
        marginBottom: 8,
    },
    "4": {
        fontSize: ".9rem",
        fontWeight: 600,
        marginBottom: 6,
    },
}

function Heading({
    level,
    children,
    ...rest
}: {
    level: "1" | "2" | "3" | "4"
    children: ReactNode
} & ComponentProps<"h1">) {
    const El = `h${level}` as ElementType
    return (
        <El css={css({ breakAfter: "avoid" }, headingLevelsCss[level])} {...rest}>
            {children}
        </El>
    )
}

const spacing = { sm: "1rem", md: "1.6rem", lg: "2.4rem", xl: "4rem" }

const bodyTextCss = css({
    fontFamily: "serif",
    textAlign: "justify",
    // Rules to ensure long words (or rather URLs which is the usual suspect) breaks to
    // next line instead of causing containers to grow larger than they should.
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    li: {
        marginBottom: 8,
        p: { marginBottom: 8 },
        "> *:last-child": { marginBottom: 0 },
    },
})
const twoColumnsCss = css({
    columns: 2,
    columnGap: spacing.lg,
    textAlign: "justify",
    // Divs that span across columns tend to suffer from margin collapse for children.
    "& > div": { display: "flow-root" },
})

const figureCss = css(
    {
        marginLeft: -16,
        marginRight: -16,
        fontFamily: "Jost, sans-serif",
        "table td p": {
            fontFamily: "Jost, sans-serif",
        },
        "table td div": {
            marginLeft: "0 !important",
        },
    },
    css`
        table {
            break-before: avoid;
            break-inside: avoid !important;
        }
    `
)

const chartGridCss = css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gap: spacing.md,
})

function ProductionProjectionChartWidgetAPIView(props: ProductionProjectionChartParams) {
    return <WidgetAPIView name="ProductionProjectionChart" param={JSON.stringify(props)} />
}

function OfferingAnalysisChartWidgetAPIView(props: OfferingAnalysisChartParams) {
    return <WidgetAPIView name="OfferingAnalysisChart" param={JSON.stringify(props)} />
}

function ImpactOverviewTableWidgetAPIView(props: ImpactOverviewTableParams) {
    return <WidgetAPIView name="ImpactOverviewTable" param={JSON.stringify(props)} />
}

function Section({ children, ...rest }: { children: ReactNode } & ComponentProps<"section">) {
    return (
        <section css={css(sectionCss)} {...rest}>
            {children}
        </section>
    )
}

const articleSectionCss = css({ marginBottom: 8 })
