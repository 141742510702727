import { YTools } from "../../packages/y/YTools"
import { Property } from "../../reactor"

export type Editor = (props: { obj: YTools.Node; property: Property }) => JSX.Element

/**
 * A list of functions that can be used to launch a custom editor for a
 * TypeScript property. Use the `@editor FunctionName` attribute to point to a
 * function.
 */
export const Editors = new Map<string, Editor>()

export function Editor(name: string, editor: Editor) {
    Editors.set(name, editor)
}
