import { useState } from "react"
import { useStudioTableColumnFilterOptions } from "../../studio/client"
import { ColorStyles } from "../ui"
import { WidgetView } from "./WidgetView"
import { Spinner } from "react-bootstrap"

export function TableSelectFilter({
    collectionName,
    columnName,
    selection,
    onChange,
}: {
    collectionName: string
    columnName: string
    selection?: any[]
    onChange: (selection: any[] | undefined, widgets: any[]) => void
}) {
    const [search, setSearch] = useState("")
    const options = useStudioTableColumnFilterOptions(collectionName, columnName, search, 0, 50)

    return (
        <div
            style={{
                minWidth: "200px",
                fontSize: 14,
                color: ColorStyles.gray[700],
            }}
        >
            <input
                className="form-control"
                placeholder="Search"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 8, position: "sticky" }}
            />
            <div
                style={{
                    maxHeight: "300px",
                    minHeight: 100,
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {options.loading && (
                    <Spinner
                        animation="border"
                        style={{ alignSelf: "center", justifySelf: "center", marginTop: 32 }}
                    />
                )}
                {options.data?.rows.map((opt) => (
                    <div
                        key={opt.value.toString()}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            minWidth: "200px",
                        }}
                    >
                        <input
                            style={{
                                width: "20px",
                                height: "20px",
                                accentColor: ColorStyles.primary[500],
                            }}
                            type="checkbox"
                            checked={selection?.includes(opt.value)}
                            onChange={() => {
                                const newSelection = selection?.includes(opt.value)
                                    ? selection.filter((v) => v !== opt.value)
                                    : [...(selection ?? []), opt.value]
                                const newWidgets = newSelection.map(
                                    (v) => options.data?.rows.find((r) => r.value === v)?.widget
                                )

                                onChange(
                                    newSelection.length > 0 ? newSelection : undefined,
                                    newWidgets
                                )
                            }}
                        />
                        <WidgetView
                            value={
                                typeof opt.widget === "object"
                                    ? { width: "100%", ...opt.widget }
                                    : opt.widget
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
