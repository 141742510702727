import { Default, Uuid } from "../../reactor"

export type EmailSection = {
    readonly type: string
    readonly id: Uuid<"EmailSection">
}

/**
 * A function component that renders an email section.
 */
export type EmailSectionFunction<T> = (section: T) => JSX.Element

/**
 * Registers an email section type specific to the model.
 *
 * The calling file must be placed in the `email` directory of the model to be picked up
 * by reflection.
 */
export function EmailSection<T>(
    /** The name of the renderer function.
     *
     * This must be provided if the renderer function is exported, as top-level
     * exported functions get renamed by Parcel's scope hoisting feature. */
    name: string,
    renderer: EmailSectionFunction<T>,
    /**
     * A function that provides default values for the section. This can be used
     * instead of or in addition to using the `@default` tag on the properties,
     * to provide programatic defaults.
     */
    defaultProvider?: (t: T) => T | void
): void {
    EmailSection.sections.set(name, renderer as any)
    if (defaultProvider)
        Default<T>((t) => {
            // Make sure we preserve the implicit id and type properties.
            const r = defaultProvider(t)
            if (r && t && typeof t === "object") {
                Object.assign(t, r)
            }
        }, name)
}
EmailSection.sections = new Map<string, EmailSectionFunction<any>>()
