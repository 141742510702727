import {
    ArrayType,
    CreateDefault,
    GetTypeAlias,
    GetTypeProps,
    IsArrayType,
    IsNumberType,
    IsStringType,
    IsUnionType,
    MatchesType,
    prettyCamel,
    Property,
    Type,
    GetIconForTypedValue,
    GetReflectionInfo,
    GetType,
    SubstituteAndDiscriminate,
    Uuid,
    Assert,
    Reference,
    IsFileType,
    UninitializedDefaults,
    TypeToString,
    UninitializedArrayValue,
} from "../../reactor"
import { Duplicate } from "../../reactor/Types/Duplicate"
import React, { createContext, useCallback, useContext, useRef, useState } from "react"
import { useDebounceContent, usePreference } from "../../reactor/Web"
import { RButton } from "./Buttons"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { PropView } from "./PropView"
import { ToolButton } from "./ToolButton"
import { PropRow } from "./PropRow"
import { ObjectPathProvider, ReadonlyContext, useIsReadonlyField } from "./ObjectContext"
import { useFindSessionMethods } from "./SessionContext"
import { CollapseContext, ListContext } from "./ListContext"
import { Icon } from "./Icon"
import { useSettingsNamespace } from "./SettingsContext"
import { Widget, WidgetMap } from "../../packages/widgets/Widget"
import { ExtractPrimaryItemFromWidget } from "../../packages/widgets/Helpers"
import { WidgetView } from "../../packages/widgets/WidgetView"
import { BorderContext } from "./BorderContext"
import { DocumentPicker } from "./DocumentPicker"
import { None } from "./None"
import { useHover } from "../../packages/hooks/useHover"
import { useDocumentObjectView } from "../client"
import { Modal } from "../../packages/modal/Modal"
import { SelectionContext } from "../../packages/editing/SelectionContext"
import { useFullHeight } from "../../packages/height-lock/HeightLockContext"
import { GridView } from "./GridView"
import { ColorStyles } from "../../packages/ui"
import {
    ClientSideLocalize,
    Translations,
} from "../../packages/localization/client-side/Dictionary"
import { useCurrentLocale } from "../../packages/localization/client-side/useLocalize"
import { useDocument, useDocumentRefresh } from "./DocumentContext"
import {
    ObjectPath,
    ObjectPathsEqual,
    ResolveObjectPath,
    ResolveObjectPathType,
} from "../../reactor/ObjectPath"
import * as Y from "yjs"
import { YTools } from "../../packages/y/YTools"
import { YProxy } from "../../packages/y/YProxy"

function canInline(t: Type): boolean {
    return (
        IsStringType(t) ||
        IsNumberType(t) ||
        IsFileType(t) ||
        (IsUnionType(t) && t.union.every(canInline))
    )
}

export function ListPropView({
    buttons,
    label,
    property,
    obj,
    hideBadge,
    isRoot,
}: {
    buttons: ToolButton[]
    property: Property
    label?: string
    obj: YTools.Node
    hideBadge?: boolean
    isRoot: boolean
}) {
    const { type, name, tags, description } = property
    const arr = YTools.get(obj, property.name)

    if (arr && !(arr instanceof Y.Array)) throw new Error("Expected Y.Array")

    if (!IsArrayType(type)) throw new Error()

    const expand = tags?.expand
    const [show, setShow] = useState(expand)

    /** Objects that have been newly created and should therefore have readonly
     * fields editable until saved. */
    const [newlyCreated, setNewlyCreated] = useState<any[]>([])
    const locale = useCurrentLocale()

    const { updateableSubtree } = useContext(ReadonlyContext)
    const isReadonly = useIsReadonlyField(property)
    const [mode, setMode] = usePreference<"regular" | "grid">(
        `listmode-${TypeToString(type.array)}`,
        property.tags?.grid ? "grid" : "regular"
    )
    const shallowLocked = property.tags?.shallowLocked

    const hasValues = !!arr
    const isLocked = isReadonly || shallowLocked
    const addButton = !hasValues && !isLocked && (
        <RButton
            variant="secondary"
            onClick={() => {
                const newObj = CreateDefault(type.array, UninitializedDefaults, locale)
                setNewlyCreated([...newlyCreated, newObj])
                YTools.set(obj, property.name, YTools.valueToYValue([newObj]))
            }}
        >
            {Translations.Add()}
        </RButton>
    )

    let content =
        isReadonly && !arr ? (
            <None />
        ) : (
            <ReadonlyContext.Provider
                value={{ mode: isReadonly ? "readonly" : "edit", updateableSubtree }}
            >
                {(hideBadge || show) && arr && (
                    <MasterDetailView
                        propName={name}
                        arr={arr}
                        inline={canInline(type.array)}
                        newlyCreated={newlyCreated}
                        isReadonly={isReadonly}
                        isRoot={isRoot}
                        itemType={type.array}
                    >
                        <ListView
                            path={[]}
                            newlyCreated={newlyCreated}
                            setNewlyCreated={setNewlyCreated}
                            property={property}
                            obj={obj}
                            isReadonly={isReadonly}
                            isRoot={isRoot}
                            itemIcon={property.tags?.icon || undefined}
                        />
                    </MasterDetailView>
                )}
            </ReadonlyContext.Provider>
        )

    if (mode === "grid") {
        content = <GridView property={property} obj={obj} />
    }

    return (
        <ObjectPathProvider name={property.name} obj={obj} type={property.type}>
            <CollapseContext.Provider
                value={{
                    collapse() {
                        setShow(false)
                    },
                }}
            >
                {isRoot ? (
                    <>
                        {addButton}
                        {content}
                    </>
                ) : (
                    <PropRow
                        isReadonly={isReadonly}
                        buttons={[
                            ...buttons,
                            {
                                text: mode === "regular" ? "Grid mode" : "Disable grid mode",
                                onClick: () => setMode(mode === "regular" ? "grid" : "regular"),
                            },
                        ]}
                        description={description}
                        descriptionAbove={true}
                        label={label}
                        badge={
                            !hideBadge && (
                                <>
                                    {arr && !expand && (
                                        <RButton
                                            style={{ marginTop: 8 }}
                                            chevron={!show ? "chevron-down" : "chevron-up"}
                                            onClick={() => setShow(!show)}
                                        >
                                            {arr.length}
                                        </RButton>
                                    )}
                                    {addButton}
                                </>
                            )
                        }
                    >
                        {content}
                    </PropRow>
                )}
            </CollapseContext.Provider>
        </ObjectPathProvider>
    )
}

type MasterDetailContext = {
    selectedPath: ObjectPath
    selectedObject: unknown
    select(path: ObjectPath, wasDragDrop?: boolean): void
}

const masterDetailContext = createContext<MasterDetailContext | null>(null)

const uniqueKeyMap = new WeakMap<any, string>()

function MasterDetailView({
    itemType,
    arr,
    children,
    inline,
    propName,
    isRoot,
    isReadonly,
    newlyCreated,
}: {
    itemType: Type
    children: React.ReactNode
    inline: boolean
    propName: string
    arr: Y.Array<any>
    isRoot: boolean
    isReadonly: boolean
    newlyCreated: any[]
}) {
    // Conditional hook should be safe, since `isRoot` is constant
    if (isRoot) useFullHeight()

    const listRef = useRef<HTMLDivElement>(null)
    const settingsNamespace = useSettingsNamespace()
    const [width, setWidth] = usePreference<number>(`${settingsNamespace}-${propName}-width`, 400)
    const { hover, hoverProps } = useHover()

    const { drawBorders } = useContext(BorderContext)

    const selectionContext = useContext(SelectionContext)

    const [selectedPath, select] = useState<ObjectPath>([])
    const selectedObject = ResolveObjectPath(arr, selectedPath)
    const selectedObjectParent = (
        selectedPath.length === 0 ? undefined : ResolveObjectPath(arr, selectedPath.slice(0, -1))
    ) as YTools.Node | undefined

    if (
        selectedObjectParent &&
        !(selectedObjectParent instanceof Y.Array || selectedObjectParent instanceof Y.Map)
    ) {
        throw new Error("Expected Y.Array or Y.Map")
    }

    const selectedIndex = selectedPath.length ? selectedPath.slice(-1)[0].toString() : undefined
    const selectedObjectType = ResolveObjectPathType({ array: itemType }, arr, selectedPath)

    return (
        <masterDetailContext.Provider
            value={{
                selectedPath,
                selectedObject,
                select(path, wasDragDrop) {
                    if (selectionContext) {
                        if (wasDragDrop) return // Don't select when drag-dropping in selection context
                        const obj = ResolveObjectPath(arr, path)
                        const type = ResolveObjectPathType({ array: itemType }, arr, path)
                        if (type) selectionContext.setSelectedObject(YProxy(obj), type)
                    } else select(path)
                },
            }}
        >
            <div
                style={{
                    color: ColorStyles.gray[600],
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                }}
            >
                <BorderContext.Provider value={{ drawBorders: false }}>
                    <div
                        ref={listRef}
                        style={{
                            width: selectedPath.length && !inline ? width : "100%",
                            height: isRoot ? "100%" : undefined,
                            overflowX: "clip",
                            overflowY: "auto",
                            border:
                                !isRoot && drawBorders
                                    ? `1px solid ${ColorStyles.gray[200]}`
                                    : undefined,
                            borderRadius: !isRoot ? 8 : undefined,
                        }}
                    >
                        {children}
                    </div>
                </BorderContext.Provider>
                {selectedPath.length > 0 && !inline && (
                    <div
                        {...hoverProps}
                        onPointerDown={(e) => {
                            ;(e.target as HTMLDivElement).setPointerCapture(e.pointerId)
                        }}
                        onPointerUp={(e) => {
                            ;(e.target as HTMLDivElement).releasePointerCapture(e.pointerId)
                        }}
                        onMouseMove={(e) => {
                            if (e.buttons && typeof width === "number") {
                                setWidth(width + e.movementX)
                            }
                        }}
                        style={{
                            width: 12,
                            borderLeft: hover ? "1px solid " + ColorStyles.primary[500] : undefined,
                            cursor: "ew-resize",
                        }}
                    />
                )}

                {selectedIndex && selectedObjectType && !inline && selectedObjectParent && (
                    <div
                        // We need to make sure each combination of object and index gets its unique key, otherwise React will try to reuse the UI state of the tree when the selected object changes
                        key={(() => {
                            // If we have an "id" field, use that directly to avoid glitches
                            if (
                                selectedObject &&
                                typeof selectedObject === "object" &&
                                "id" in selectedObject &&
                                typeof (selectedObject as any).id === "string" &&
                                (selectedObject as any).id // avoid empty strings
                            )
                                return (selectedObject as any).id
                            let res = uniqueKeyMap.get(selectedObjectParent)
                            if (res === undefined) {
                                uniqueKeyMap.set(selectedObjectParent, (res = `${Math.random()}`))
                            }
                            return res + selectedIndex
                        })()}
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            // Some padding at the bottom makes it more pleasant to scroll to the bottom
                            paddingBottom: isRoot ? 256 : 0,
                            height: isRoot ? "100%" : undefined,
                            overflowY: isRoot ? "scroll" : undefined,
                        }}
                    >
                        <ReadonlyContext.Provider
                            value={{
                                mode: isReadonly
                                    ? "readonly"
                                    : newlyCreated.includes((arr as any)[selectedIndex])
                                      ? "create"
                                      : "edit",
                            }}
                        >
                            <NestedPathProvider
                                path={selectedPath.slice(0, selectedPath.length - 1)}
                                obj={arr}
                            >
                                <PropView
                                    obj={selectedObjectParent}
                                    mode={"inline"}
                                    isEmbedded={true}
                                    property={{
                                        name: selectedIndex,
                                        type: SubstituteAndDiscriminate(
                                            selectedObject instanceof Y.Array ||
                                                selectedObject instanceof Y.Map
                                                ? YTools.valueToJSON(selectedObject)
                                                : selectedObject,
                                            selectedObjectType
                                        ),
                                        isReadonly: false,
                                    }}
                                    buttons={[]}
                                />
                            </NestedPathProvider>
                        </ReadonlyContext.Provider>
                    </div>
                )}
            </div>
        </masterDetailContext.Provider>
    )
}

function NestedPathProvider({
    path,
    obj,
    children,
}: {
    path: ObjectPath
    obj: any
    children: React.ReactNode
}): JSX.Element {
    if (path.length === 0) return <>{children}</>
    const p = path[0]

    return (
        <ObjectPathProvider obj={obj} name={path[0]} type="unknown">
            <NestedPathProvider
                path={path.slice(1)}
                obj={
                    obj instanceof Y.Array
                        ? obj.get(typeof p === "number" ? p : parseInt(p))
                        : obj
                          ? obj[p]
                          : undefined
                }
            >
                {children}
            </NestedPathProvider>
        </ObjectPathProvider>
    )
}

export function ListView({
    property,
    obj,
    itemIcon,
    path,
    isReadonly,
    isRoot,
    newlyCreated,
    setNewlyCreated,
}: {
    property: Property
    obj: YTools.Node
    itemIcon?: string
    path: ObjectPath
    isReadonly: boolean
    isRoot: boolean
    newlyCreated: any[]
    setNewlyCreated: (nc: any[]) => void
}) {
    const arr = YTools.getArray(obj, property.name)

    const arrType = property.type as ArrayType
    const itemType = arrType.array

    const props = GetTypeProps(itemType)
    const titleProp = props.find((p) => p.tags?.title)
    const { select, selectedObject } = Assert(useContext(masterDetailContext))
    const locale = useCurrentLocale()

    const doc = useDocument()
    const refresh = useDocumentRefresh()
    const listRef = useRef<HTMLDivElement>(null)

    const typeAlias = GetTypeAlias(itemType)
    const debouncedViewProps = useDebounceContent(
        arr.length && typeAlias
            ? {
                  objects: arr.map((o: any) => ({
                      obj: YTools.valueToJSON(o),
                      typeAlias,
                  })),
                  document: doc ? YTools.valueToJSON(doc) : undefined,
              }
            : null, // No need to fetch if there are no items
        500,
        // Don' fetch while we are editing a field, as this will make the editor
        // sluggish for large documents.
        typeof document !== "undefined" ? listRef.current?.contains(document.activeElement) : false
    )

    const { data: views } = useDocumentObjectView(debouncedViewProps)

    const isShallowLocked = property.tags?.shallowLocked

    const inline = canInline(itemType)

    const [objectKeys] = useState(new Map<any, string>())
    const optionsFunc = property.tags?.options || undefined
    const showIndex = !!property.tags?.showIndex

    const getEntityKey = useCallback(
        (entity: unknown) => {
            const existing = objectKeys.get(entity)
            if (existing) return existing

            let key: string

            if (entity instanceof Y.Map) {
                key = entity.get("id")
            } else if (typeof entity === "string" && Uuid.pattern.test(entity)) {
                key = entity
            }
            key ??= Uuid().valueOf()

            objectKeys.set(entity, key)

            return key
        },
        [objectKeys]
    )

    async function addNew() {
        async function findInstanceType() {
            const alias = GetTypeAlias(arrType.array)
            if (alias === "Section" || alias === "EmailSection" || IsUnionType(arrType.array)) {
                if (alias) {
                    const res = await Modal<WidgetMap>((close) => (
                        <DocumentPicker
                            collection={alias}
                            current={undefined}
                            cancel={() => close(undefined)}
                            itemName={{ en: prettyCamel(alias, false) + " type" }}
                            itemSelected={(row) => close(row)}
                        />
                    ))
                    if (res === undefined) return // User cancelled

                    const pk = res?._primaryKey
                    if (typeof pk === "string") {
                        return GetType(pk)
                    }
                }
            }
            return itemType
        }

        const instanceType = await findInstanceType()
        if (!instanceType) return

        if (typeof instanceType === "object" && instanceType.reference) {
            const collectionOrOptions =
                property.tags?.options ??
                GetReflectionInfo().collections.find(
                    (c) => GetTypeAlias(c.type.typeArgs[0]) === instanceType.reference?.typeName
                )?.name
            if (!collectionOrOptions) {
                throw new Error(
                    "Collection or options function for reference not found: " +
                        JSON.stringify(instanceType.reference)
                )
            }
            const picked = await Modal<WidgetMap>((close) => (
                <DocumentPicker
                    collection={collectionOrOptions}
                    current="value goes here"
                    document={doc}
                    itemSelected={(row) => close(row)}
                    cancel={() => close(undefined)}
                    obj={obj}
                    refKey={instanceType.reference?.fieldName}
                />
            ))
            if (picked) {
                if (!picked._primaryKey) {
                    alert("No primary key")
                }

                const pos = arr.length

                arr.insert(pos, [picked._primaryKey])
                select([...path, pos])

                if (refresh) void refresh()
            }
            return
        }

        const newObj = YTools.valueToYValue(
            CreateDefault(instanceType, UninitializedArrayValue, locale)
        )
        setNewlyCreated([...newlyCreated, newObj])

        const pos = arr.length
        // Y.js does not support inserting `undefined` into an array, so we
        // represent that as `null`, if it happens to be the `undefined` due to
        // tweaked defaults.
        arr.insert(pos, [newObj === undefined ? null : newObj])
        select([...path, pos])

        if (refresh) void refresh()
    }

    return (
        <ListContext.Provider value={{ optionsFunc, showIndex }}>
            <DragDropContext
                onDragEnd={(result) => {
                    if (!result.destination) {
                        return
                    }
                    const src = result.source.index
                    const dst = result.destination.index

                    if (arr.doc)
                        Y.transact(arr.doc, () => {
                            // Y.js does not support move semantics
                            // https://discuss.yjs.dev/t/moving-elements-in-lists/92/19
                            // So we have to copy the item, delete the original
                            // and insert the copy at the new position
                            const item = YTools.valueToYValue(
                                YTools.valueToJSON(arr.get(src) as any)
                            )
                            arr.delete(src, 1)
                            arr.insert(dst, [item])
                        })

                    // Since views take some time to re-calculate, let's predict it locally to avoid
                    // popping
                    if (views) {
                        const [removedView] = views.views.splice(src, 1)
                        views.views.splice(dst, 0, removedView)
                    }

                    if (selectedObject) {
                        select([...path, result.destination.index], true)
                    }

                    if (refresh) void refresh()
                }}
            >
                <div ref={listRef} style={{ width: "100%" }}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                            return (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {arr.map((entity: any, i: number) => {
                                        let widget =
                                            (views
                                                ? views.views[i]
                                                : titleProp
                                                  ? entity instanceof Y.Map ||
                                                    entity instanceof Y.Array
                                                      ? YTools.get(entity, titleProp.name)
                                                      : entity[titleProp.name]
                                                  : undefined) || `${typeAlias || "Item"} ${i + 1}`

                                        if (widget instanceof Y.Map) {
                                            widget = YTools.valueToJSON(widget)
                                        }

                                        const key =
                                            entity === null || typeof entity === "string"
                                                ? i.toString()
                                                : getEntityKey(entity)

                                        return (
                                            <ListItem
                                                id={key}
                                                key={key}
                                                path={[...path, i]}
                                                index={i}
                                                icon={itemIcon}
                                                type={arrType}
                                                arr={arr}
                                                newlyCreated={newlyCreated}
                                                setNewlyCreated={setNewlyCreated}
                                                inline={
                                                    inline ? { arr, type: itemType } : undefined
                                                }
                                                widget={ExtractPrimaryItemFromWidget(widget)}
                                                containsErrors={
                                                    MatchesType(
                                                        YTools.valueToJSON(entity),
                                                        arrType.array
                                                    ) !== true
                                                }
                                                duplicate={() => {
                                                    const newObj = YTools.valueToYValue(
                                                        Duplicate(
                                                            YTools.valueToJSON(entity),
                                                            arrType.array
                                                        )
                                                    )
                                                    arr.insert(i + 1, [newObj])

                                                    if (refresh) void refresh()
                                                }}
                                                remove={() => {
                                                    arr.delete(i, 1)

                                                    if (i - 1 < 0) select(path)
                                                    else select([...path, i - 1])

                                                    if (refresh) void refresh()
                                                }}
                                                isReadonly={isReadonly}
                                                isRoot={isRoot}
                                                isShallowLocked={!!isShallowLocked}
                                            />
                                        )
                                    })}
                                    {provided.placeholder}

                                    {!isReadonly && !isShallowLocked && (
                                        <RButton
                                            style={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            }}
                                            variant="secondary"
                                            disabled={property.isReadonly}
                                            onClick={addNew}
                                        >
                                            {property.isReadonly
                                                ? "None"
                                                : Translations.Add() +
                                                  " " +
                                                  ((typeof itemType === "object" &&
                                                  "reference" in itemType &&
                                                  itemType.reference
                                                      ? prettyCamel(
                                                            localizeReference(itemType.reference),
                                                            false
                                                        )
                                                      : undefined) ??
                                                      prettyCamel(
                                                          ClientSideLocalize({
                                                              en: GetTypeAlias(itemType),
                                                              ...(typeof itemType === "object"
                                                                  ? itemType.tags?.translation
                                                                  : undefined),
                                                          }) ??
                                                              (GetTypeAlias(itemType) || "item"),
                                                          false
                                                      ))}
                                        </RButton>
                                    )}
                                </div>
                            )
                        }}
                    </Droppable>
                </div>
            </DragDropContext>
        </ListContext.Provider>
    )
}

function localizeReference(ref: Reference) {
    const type = GetType(ref.typeName)
    if (typeof type === "object") {
        return ClientSideLocalize({
            en: ref.typeName,
            ...(type.tags?.translation || {}),
        })
    }
    return ref.typeName
}

function ListItem({
    id,
    inline,
    arr,
    widget,
    index,
    containsErrors,
    icon,
    remove,
    duplicate,
    path,
    type,
    isReadonly,
    isRoot,
    newlyCreated,
    setNewlyCreated,
    isShallowLocked,
}: {
    id: string
    inline?: { arr: Y.Array<any>; type: Type }
    arr: Y.Array<any>
    widget: Widget
    index: number
    icon?: string
    containsErrors: boolean
    type: ArrayType
    /** Path relative to the root array */
    path: ObjectPath
    remove: () => void
    duplicate: () => void
    isReadonly: boolean
    isRoot: boolean
    newlyCreated: any[]
    setNewlyCreated: (nc: any[]) => void
    isShallowLocked: boolean
}) {
    const mdc = useContext(masterDetailContext)
    if (!mdc) throw new Error("No master-detail context")
    const { selectedPath, select } = mdc
    const selected = ObjectPathsEqual(path, selectedPath)

    const editor = inline && (
        <PropView
            obj={inline.arr}
            property={{
                optional: false,
                name: index.toString(),
                type: inline.type,
                isReadonly: false,
            }}
            mode={"inline"}
            buttons={[]}
            isEmbedded={true}
        />
    )

    const { hover, hoverProps } = useHover()
    const methods = useFindSessionMethods()(type.array, index)
    const { collapse } = useContext(CollapseContext)
    const listContext = useContext(ListContext)
    const showIndex = listContext?.showIndex

    const discriminatedType = IsUnionType(type.array)
        ? SubstituteAndDiscriminate(YTools.valueToJSON(arr.get(index)), type.array)
        : type.array

    const subLists = GetTypeProps(discriminatedType).filter(
        (x) => IsArrayType(x.type) && x.tags?.subtree
    )

    icon ||=
        typeof widget === "object" ? undefined : GetIconForTypedValue(arr.get(index), type.array)

    const settingsNamespace = useSettingsNamespace()
    const [expanded, setExpanded] = usePreference(`${settingsNamespace}-${path}-expanded`, false)

    const showSeparator = !(index === arr.length - 1 && (isReadonly || isShallowLocked))

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={isReadonly || isShallowLocked}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={{
                        flexDirection: "column",
                        display: "flex",
                        ...provided.draggableProps.style,
                    }}
                    {...provided.draggableProps}
                >
                    <div
                        onClick={() => {
                            if (inline) return
                            if (selected) {
                                select([])
                            } else {
                                select(path)
                            }
                        }}
                        {...provided.dragHandleProps}
                        {...hoverProps}
                        style={{
                            cursor: inline ? undefined : "pointer",
                            borderColor: snapshot.isDragging
                                ? ColorStyles.primary[500] + "22"
                                : selected
                                  ? ColorStyles.primary[500] + 33
                                  : undefined,
                            border: selected ? `1px solid ${ColorStyles.primary}99` : undefined,
                            borderTopLeftRadius: !isRoot && index === 0 ? 8 : 0,
                            borderTopRightRadius: !isRoot && index === 0 ? 8 : 0,

                            backgroundColor: selected
                                ? ColorStyles.primary + "11"
                                : hover
                                  ? ColorStyles.primary[500] + "11"
                                  : undefined,
                            fontWeight: selected ? "bold" : undefined,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        {showIndex && <div style={{ paddingLeft: 16 }}>{index}</div>}
                        {icon && (
                            <Icon
                                icon={icon}
                                style={{ marginLeft: 8, marginRight: 8 }}
                                color={ColorStyles.gray[300]}
                            />
                        )}
                        {subLists.length > 0 && (
                            <RButton
                                key="sublist-button"
                                style={{ marginLeft: 8, paddingLeft: 4, paddingRight: 4 }}
                                icon={expanded ? "chevron-down" : "chevron-right"}
                                onClick={() => setExpanded(!expanded)}
                            />
                        )}

                        <div style={{ flex: 1, opacity: inline || widget ? 1 : 0.5 }}>
                            {editor || (
                                <div
                                    style={{
                                        padding: 8,
                                    }}
                                >
                                    <WidgetView
                                        value={widget}
                                        hideDetails={!!selectedPath.length}
                                    />
                                </div>
                            )}
                        </div>
                        {containsErrors && !inline && (
                            <span key="incomplete-label" style={{ color: ColorStyles.error[500] }}>
                                {Translations.Incomplete()}
                            </span>
                        )}
                        {methods.map((m) => (
                            <RButton
                                key={m.name}
                                onClick={() => {
                                    m.call()
                                    if (collapse) collapse()
                                }}
                            >
                                {prettyCamel(m.name)}
                            </RButton>
                        ))}
                        {!isReadonly && !isShallowLocked && (
                            <RButton
                                icon="ui-dots-vertical"
                                hideChevron={true}
                                iconSize={16}
                                style={{ marginRight: 8 }}
                                popup={(close) => (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <WidgetView
                                            value={{
                                                type: "Button",
                                                icon: "ui-trash-01",
                                                text: "Remove",
                                                action: remove,
                                            }}
                                        />
                                        <WidgetView
                                            value={{
                                                type: "Button",
                                                icon: "ui-copy-01",
                                                text: "Duplicate",
                                                action: duplicate,
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    </div>

                    {expanded && (
                        <div style={{ paddingLeft: 36 }}>
                            {subLists.map((lp) => {
                                const obj = YTools.getMap(arr, index).get(lp.name)
                                if (obj) {
                                    return (
                                        <ListView
                                            key={lp.name}
                                            isReadonly={isReadonly}
                                            obj={YTools.getNode(arr, index)}
                                            property={lp}
                                            path={[...path, lp.name]}
                                            isRoot={false}
                                            setNewlyCreated={setNewlyCreated}
                                            newlyCreated={newlyCreated}
                                        />
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                    {showSeparator && <div style={{ height: 1, backgroundColor: "#eee" }} />}
                </div>
            )}
        </Draggable>
    )
}
