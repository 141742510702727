import { CSSProperties } from "react"
import { RButton } from "../../studio/Views/Buttons"
import { ColorName, ColorStyles } from "../ui"
import { useLocalize } from "../localization/client-side/useLocalize"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { Icon } from "../../studio/Views/Icon"
import {
    deleteStudioTableFilter,
    postStudioTableFilter,
    putStudioTableFilter,
} from "../../studio/client"
import { Modal } from "../modal/Modal"
import { FilterEditor } from "./FilterEditor"
import type { getStudioTableFilters } from "../../studio/API/StudioTableFilters"

export type TableFilter = {
    id: Uuid<"StudioTableFilter">
    mode: "include" | "exclude"
}

const buttonStyle = {
    border: `1px solid ${ColorStyles.gray[300]}`,
    fontSize: 14,
    boxShadow: "0px 1px 2px 0px #1018280D",
}

export function TableWidgetFilters(
    localize: ReturnType<typeof useLocalize>,
    propsfilters: ReturnType<typeof getStudioTableFilters> | undefined,
    filters: TableFilter[],
    setFilters: (filters: TableFilter[]) => void,
    collectionName: string,
    refresh: () => void
) {
    return (
        <RButton
            style={{ ...buttonStyle, marginLeft: 12, fontSize: 14 }}
            active={filters.length > 0}
            popup={(close) => {
                return (
                    <div>
                        {propsfilters?.map((f) => {
                            const existing = filters.find((x) => x.id === f.id)
                            const include = existing && existing.mode === "include"
                            const exclude = existing && existing.mode === "exclude"

                            const tickButtonStyle = (
                                active: boolean,
                                color: ColorName = "success"
                            ): CSSProperties => ({
                                width: 32,
                                height: 32,
                                paddingLeft: 5,
                                paddingRight: 5,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderRadius: 6,
                                fontSize: 14,
                                color: ColorStyles[color][600],
                                background: ColorStyles[color][active ? 400 : 50],
                                border: "1px solid " + ColorStyles[color][300],
                            })

                            return (
                                <div
                                    key={f.id.valueOf()}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingTop: 2,
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                        paddingBottom: 2,
                                        borderRadius: 6,
                                        border: "1px solid " + ColorStyles.gray[300],
                                    }}
                                >
                                    <div style={{ marginRight: 24, fontSize: 14 }}>
                                        {localize(f.description)}
                                    </div>
                                    <button
                                        style={tickButtonStyle(!!include)}
                                        onClick={() => {
                                            if (existing) {
                                                if (existing.mode === "include") {
                                                    setFilters(filters.filter((x) => x.id !== f.id))
                                                } else {
                                                    setFilters(
                                                        filters.map((x) =>
                                                            x.id === f.id
                                                                ? { ...f, mode: "include" }
                                                                : x
                                                        )
                                                    )
                                                }
                                            } else {
                                                setFilters([...filters, { ...f, mode: "include" }])
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-plus" />
                                    </button>
                                    <button
                                        style={tickButtonStyle(!!exclude)}
                                        onClick={() => {
                                            if (existing) {
                                                if (existing.mode === "exclude") {
                                                    setFilters(filters.filter((x) => x.id !== f.id))
                                                } else {
                                                    setFilters(
                                                        filters.map((x) =>
                                                            x.id === f.id
                                                                ? { ...f, mode: "exclude" }
                                                                : x
                                                        )
                                                    )
                                                }
                                            } else {
                                                setFilters([...filters, { ...f, mode: "exclude" }])
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-minus" />
                                    </button>
                                    <button
                                        style={{
                                            ...tickButtonStyle(false, "error"),
                                            marginLeft: 6,
                                        }}
                                        onClick={async () => {
                                            if (
                                                confirm(
                                                    "Are you sure you want to delete this filter?"
                                                )
                                            ) {
                                                await deleteStudioTableFilter(f.id)
                                                refresh()
                                            }
                                        }}
                                    >
                                        <Icon icon="ui-trash-01" />
                                    </button>
                                    <button
                                        style={{
                                            ...tickButtonStyle(false, "blue"),
                                            marginLeft: 6,
                                        }}
                                        onClick={async () => {
                                            await Modal((close) => {
                                                return (
                                                    <FilterEditor
                                                        title={localize({
                                                            en: "Edit filter",
                                                            no: "Rediger filter",
                                                            sv: "Redigera filter",
                                                            da: "Rediger filter",
                                                        })}
                                                        id={f.id}
                                                        code={f.filterCode}
                                                        description={f.description}
                                                        collectionName={collectionName}
                                                        onSave={async (desc, code) => {
                                                            await putStudioTableFilter(f.id, {
                                                                description: desc,
                                                                filterCode: code as any,
                                                                private: false,
                                                            })
                                                            await refresh()
                                                            close(undefined)
                                                        }}
                                                        onCancel={() => close(undefined)}
                                                    />
                                                )
                                            })
                                        }}
                                    >
                                        <Icon icon="ui-pencil-01" />
                                    </button>
                                </div>
                            )
                        })}

                        <RButton
                            icon="ui-plus"
                            variant="secondary"
                            style={{ marginTop: 8 }}
                            onClick={async () => {
                                await Modal((close) => {
                                    return (
                                        <FilterEditor
                                            title={localize({
                                                en: "New filter",
                                                no: "Nytt filter",
                                                sv: "Nytt filter",
                                                da: "Nyt filter",
                                            })}
                                            collectionName={collectionName}
                                            onSave={async (desc, code) => {
                                                await postStudioTableFilter({
                                                    collectionName,
                                                    description: desc,
                                                    filterCode: code as any,
                                                })
                                                await refresh()
                                                close(undefined)
                                            }}
                                            onCancel={() => close(undefined)}
                                        />
                                    )
                                })
                            }}
                        >
                            Add
                        </RButton>
                    </div>
                )
            }}
        >
            {filters.length === 0
                ? localize({ en: "No filters", no: "Ingen filter", sv: "Inga filter", da: "Ingen" })
                : ""}
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {filters.map((f) => (
                    <div
                        key={f.id.valueOf()}
                        style={{
                            padding: 2,
                            paddingRight: 6,
                            paddingLeft: 6,
                            borderRadius: 6,
                            color: ColorStyles[f.mode === "include" ? "success" : "error"][600],
                            border:
                                "1px solid " +
                                ColorStyles[f.mode === "include" ? "success" : "error"][300],
                        }}
                    >
                        <span style={{ marginRight: 4 }}>{f.mode === "include" ? "+" : "-"}</span>
                        {localize(
                            propsfilters?.find((x) => x.id === f.id)?.description ?? {
                                en: "(unknown filter)",
                            }
                        )}
                    </div>
                ))}
            </div>
        </RButton>
    )
}
