import { Property } from "../../reactor/Types/Type"
import { ToolButton } from "./ToolButton"
import { PropRow } from "./PropRow"
import { YTools } from "../../packages/y/YTools"
import { RichTextEditor } from "../../packages/rich-text/RichTextEditor"

export function RichTextView({
    obj,
    property,
    label,
    buttons,
    isEmbedded,
}: {
    obj: YTools.Node
    property: Property
    label?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
}) {
    return (
        <PropRow label={label} buttons={buttons} isEmbedded={isEmbedded}>
            <RichTextEditor
                obj={obj}
                property={property}
                className="form-control"
                style={{
                    width: "100%",
                    margin: isEmbedded ? 2 : undefined,
                    resize: "vertical",
                    overflow: "auto",
                }}
            />
        </PropRow>
    )
}
