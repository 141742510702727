import { useLocalize } from "../localization/client-side/useLocalize"
import { Localized } from "../localization/Localized"
import { ColorStyles } from "../ui"
import { TableColProp } from "./Table"

export function TableWidgetColumns(
    collectionName: string | undefined,
    colProps: TableColProp[],
    nonPanelColumns: (string | { name: string; displayName: Localized<string> })[],
    rawColumns: string[],
    setRawColumns: (columns: string[]) => void,
    hiddenColumns: string[],
    setHiddenColumns: (columns: string[]) => void
) {
    function toggleRawColumn(name: string) {
        if (rawColumns.includes(name)) {
            setRawColumns(rawColumns.filter((c) => c !== name))
        } else {
            setRawColumns([...rawColumns, name])
        }
    }

    const widgetColumns = nonPanelColumns.filter(
        (x) => !rawColumns.includes(typeof x === "string" ? x : x.name)
    )

    return (
        <div style={{ backgroundColor: "white", padding: 8, fontSize: 14 }}>
            <b>Visible Columns</b>

            <div style={{ fontSize: 12, color: ColorStyles["gray-cool"][500], marginTop: 8 }}>
                WIDGET COLUMNS
            </div>
            {widgetColumns.map((c, i) => {
                const cp = typeof c === "string" ? { name: c, displayName: { en: c } } : c
                return (
                    <CheckRow
                        key={cp.name}
                        {...cp}
                        checked={!hiddenColumns.includes(cp.name)}
                        onChange={(e) => {
                            if (hiddenColumns.includes(cp.name)) {
                                setHiddenColumns(hiddenColumns.filter((c) => c !== cp.name))
                            } else {
                                setHiddenColumns([...hiddenColumns, cp.name])
                            }
                        }}
                    />
                )
            })}
            {collectionName ? (
                <a
                    style={{ color: ColorStyles.primary[600] }}
                    href={`/studio/studiotablecolumns/new?collectionName=${encodeURIComponent(
                        collectionName
                    )}&everyone=true`}
                >
                    + Add new column...
                </a>
            ) : undefined}

            <div style={{ fontSize: 12, color: ColorStyles["gray-cool"][500], marginTop: 8 }}>
                RAW COLUMNS
            </div>
            {colProps
                .filter(
                    (cp) =>
                        !widgetColumns.some((x) =>
                            typeof x === "string" ? x === cp.name : x.name === cp.name
                        )
                )
                .map((cp) => (
                    <CheckRow
                        key={cp.name}
                        name={cp.name}
                        displayName={cp.displayName}
                        checked={rawColumns.includes(cp.name)}
                        onChange={(e) => toggleRawColumn(cp.name)}
                    />
                ))}
        </div>
    )
}

function CheckRow({
    name,
    displayName,
    checked,
    onChange,
}: {
    name: string
    displayName: Localized<string>
    checked: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
    const localize = useLocalize()
    const display = localize(displayName)
    return (
        <div
            key={name}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    style={{
                        marginRight: 8,
                        accentColor: ColorStyles.primary[600],
                        width: 22,
                        height: 22,
                        margin: 4,
                    }}
                />
                <div style={{ marginLeft: 4 }}>{display.trim() || <em>Dropdown menu</em>}</div>
            </div>
            <code
                style={{
                    color: ColorStyles["gray-cool"][500],
                    marginLeft: 32,
                }}
            >
                {name}
            </code>
        </div>
    )
}
