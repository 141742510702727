import { prettyCamel } from "../../reactor/Helpers"
import { GetReflectionInfo, GetType, SubstituteAndDiscriminate } from "../../reactor/ReflectionInfo"
import { CallSites, ResolveTypeScriptSourceFile } from "../../reactor/Server/CallSites"
import type { OpaqueString } from "../../reactor/Types/Opaque"
import { Tags } from "../../reactor/Types/Tags"
import {
    Type,
    GetTypeAlias,
    IsIntersectionType,
    IntersectionType,
    Property,
    GetTypeProps,
    GetTypeModel,
} from "../../reactor/Types/Type"
import { UntitledUI } from "../untitled-ui"

export type FieldSchema = {
    /** WARNING: Changing the name of an existing field will lose the field's old data. */
    name: string

    /** The display name of the field. If not specified, the name will be used. */
    label?: string

    /** Description of the field. This will appear in the UI. */
    description: string
    /** Whether a value is required for this field. */
    required: boolean

    /** The type schema for this field.
     *  @expand
     */
    schema: TypeSchema

    /**
     * The tags for this field, which can be used to customize the UI and
     * behavior of the field.
     */
    tags?: Tags
}

export type TypeExtension = {
    /** The fields to be added to the type. */
    fields: FieldSchema[]
    /** If specified, this will be displayed in the top of the extended field section. */
    title?: string
    /** Optional - color to use to shade the background of these fields to make it clear that they
     * belong to the same title. If unspecified, a random color will be assigned. Use `"none"` to
     * explicitly use no background. */
    color?: UntitledUI.Color | "none"
    /**
     * The type alias of the extension type.
     *
     * If this type extension originated from a `@extension` type, this will be
     * the alias of that type.
     */
    alias?: string

    /**
     * The tags of the extension type.
     *
     * If this type extension originated from a `@extension` type, this will be
     * the tags of that type.
     */
    tags?: Tags
}

/** A function that extends a document of type T with extra fields based on the current state of the
 * object and document. */
export type TypeExtender<T> = {
    (object: T, document: any): TypeExtension | undefined
    fileName?: string
}

export type TypeExtenderPredicate<T> = {
    (object: T, document: any): boolean
}

/** Whitelists a function that can be used as a type extender */
export function TypeExtender<T>(func: TypeExtender<T>) {
    Object.assign(func, { fileName: CallSites()[1].getFileName() })
    TypeExtender.functions.push(func)
}
TypeExtender.functions = [] as TypeExtender<any>[]
TypeExtender.predicates = [] as TypeExtenderPredicate<any>[]
export function TypeExtenderPredicate(tep: TypeExtenderPredicate<any>) {
    TypeExtender.predicates.push(tep)
}

/**
 * The key is `modelName:TypeName` for extenders defined in models
 */
const staticTypeExtenders = new Map<string, TypeExtension[]>()

/**
 * The extensions declared with the @extension attribute.
 */
TypeExtender.findStatic = (modelName: string | undefined, typeName: string) =>
    staticTypeExtenders.get(modelName ? `${modelName}:${typeName}` : typeName)

/** Used to reference a type by name or alias.
 *  @shared
 */
export type TypeName = OpaqueString<"TypeName">

/** Represents a run-time defined array type.
 *  @shared
 */
export type ArraySchema = {
    /** The element type of the array. */
    array: TypeSchema
}

/** Represents a run-time defined object type. */
export type ObjectSchema = {
    /** The fields of the object type.
     *  @expand
     */
    fields: FieldSchema[]
}

/** Represents a run-time defined reference type.
 *  @shared
 */
export type ReferenceSchema = {
    /** Name of the type of object being referenced.
     *  @options NamedTypeOptions
     */
    ref: TypeName
    /** Specifies the field that this reference should match. */
    field: string
}

/** Represents a run-time defined union type.
 *  @shared
 */
export type UnionSchema = {
    /** The types allowed in this union. */
    union: TypeSchema[]
}

/** References a preexisting type by its alias.
 *  @shared
 */
export type NamedType = {
    /** @options NamedTypeOptions */
    name: string
}

/** A more configurable form of number
 *  @shared
 */
export type NumberSchema = {
    /** Whether this number must be an integer.
     *
     *  If false, any real number is allowed.
     */
    integer: boolean
    /** The minimum value allowed. If unspecified, there is no minimum. */
    min?: number
    /** The maximum value allowed. If unspecified, there is no maximum. */
    max?: number
}

/** Represents a type that is known at runtime but cannot be serialized */
export type RuntimeTypeSchema = {
    /**
     * @reflection any
     */
    type: Type
}

/** Represents a serializable type specification.
 *
 *  Used to define and extend types at runtime in Reactor Studio.
 *  @shared
 */
export type TypeSchema =
    | "string"
    | "number"
    | "boolean"
    | "Markdown"
    | "Image"
    | NumberSchema
    | NamedType
    | ArraySchema
    | ObjectSchema
    | ReferenceSchema
    | UnionSchema
    | RuntimeTypeSchema

const typeExtendersForTypeCache = new Map<string, TypeExtender<any>[]>()

export function GetTypeExtendedFields(typeName: string, obj: any, doc: any) {
    return GetTypeExtenders(typeName)
        .map((extender) => extender(obj, doc))
        .filter((x) => !!x)
        .flatMap((te) => te.fields)
}

function addTypeExtender(typeName: string, extender: TypeExtender<any>) {
    const type = Array.from(extendedPropsCache.keys()).find((t) => GetTypeAlias(t) === typeName)
    if (type) extendedPropsCache.delete(type)

    let arr = typeExtendersForTypeCache.get(typeName)
    if (!arr) {
        arr = []
        typeExtendersForTypeCache.set(typeName, arr)
    }
    arr.push(extender)
}
export function AddTypeExtension(
    modelName: string | undefined,
    typeName: string,
    extension: TypeExtension
) {
    const key = modelName ? `${modelName}:${typeName}` : typeName
    let arr = staticTypeExtenders.get(key)
    if (!arr) {
        arr = []
        staticTypeExtenders.set(key, arr)
    }
    arr.push(extension)
}

/** Returns the type extenders for a given type.  */
export function GetTypeExtenders(typeName: string): TypeExtender<any>[] {
    const res = typeExtendersForTypeCache.get(typeName)
    if (res) return res

    // Match up the correct functions based on their declaration order in the source file
    const arr: TypeExtender<any>[] = []
    typeExtendersForTypeCache.set(typeName, arr)
    for (const f of GetReflectionInfo().typeExtenders) {
        for (let i = 0; i < f.types.length; i++) {
            if (f.types[i] === typeName) {
                let c = 0
                for (let n = 0; n < TypeExtender.functions.length; n++) {
                    const fn = TypeExtender.functions[n]
                    if (!fn.fileName) continue
                    const sourcePath = ResolveTypeScriptSourceFile(fn.fileName)

                    if (sourcePath === f.sourcePath) {
                        if (c === i) {
                            arr.push(fn)
                        }
                        c++
                    }
                }
            }
        }
    }
    return arr
}

/** Returns the type extensions for a value of a given type. */
export function GetTypeExtensions(type: Type, value: any, document: any): TypeExtension[] {
    const extensions: TypeExtension[] = []

    const specificType = SubstituteAndDiscriminate(value, type)
    if (specificType !== type) extensions.push(...GetTypeExtensions(specificType, value, document))

    const alias = GetTypeAlias(type)
    const extenders = alias ? GetTypeExtenders(alias) : []
    extensions.push(
        ...extenders
            .map((e) => {
                try {
                    return e(value, document)
                } catch {
                    // Errors are some times to be expected, e.g. when adding new
                    // items to a list that are not completely filled out yet.
                    return undefined
                }
            })
            .filter((e) => !!e)
    )

    return extensions
}

export function VisitExtendedFields(
    type: Type,
    obj: any,
    doc: any,
    visitor: (v: any, schema: TypeSchema) => void
): void {
    function visitSchema(v: any, f: TypeSchema): void {
        visitor(v, f)

        if (v === undefined) return
        if (v === null) return
        if (typeof f !== "object") return
        if ("union" in f) {
            f.union.forEach((u) => visitSchema(v, u))
        }
        if ("array" in f) {
            if (v instanceof Array) {
                v.forEach((e) => visitSchema(e, f.array))
            }
        }
        if ("fields" in f) {
            f.fields.forEach((field) => visitSchema(v[field.name], field.schema))
        }
    }
    GetTypeExtensions(type, obj, doc).forEach((extension) => {
        extension.fields?.forEach((f) => visitSchema(obj[f.name], f.schema))
    })
}

export function SchemaToType(t: TypeSchema): Type {
    if (t === "string") return "string"
    if (t === "number") return "number"
    if (t === "boolean") return "boolean"

    // These are shortcuts for frequently used types
    if (t === "Markdown") return GetType("Markdown")
    if (t === "Image") return GetType("Image")

    if ("integer" in t) {
        return { number: null, minValue: t.min, maxValue: t.max, integer: t.integer }
    }

    if ("name" in t) {
        return GetType(t.name)
    }
    if ("array" in t) {
        return { array: SchemaToType(t.array) }
    }
    if ("fields" in t) {
        return {
            props: FieldsToProps(t.fields),
        }
    }
    if ("ref" in t) {
        return { string: null, reference: { fieldName: t.field, typeName: t.ref.valueOf() } }
    }
    if ("union" in t) {
        return { union: t.union.map(SchemaToType) }
    }
    if ("type" in t) {
        return t.type
    }
    throw new Error("Unable to convert schema to type: " + JSON.stringify(t))
}
export function FieldsToProps(fields: FieldSchema[]): Property[] {
    return fields.map((f) => ({
        name: f.name,
        description: { en: f.description },
        type: SchemaToType(f.schema),
        optional: !f.required,
        tags: f.tags,
    }))
}

const hasTypeExtendersCache = new Map<Type, boolean>()

export function HasTypeExtenders(type: Type) {
    const cache = hasTypeExtendersCache.get(type)
    if (cache !== undefined) return cache

    const alias = GetTypeAlias(type)
    const result = alias ? GetTypeExtenders(alias).length > 0 : false

    hasTypeExtendersCache.set(type, result)
    return result
}

const extendedPropsCache = new Map<Type, readonly Property[]>()

/**
 * Returns all properties of the given type, including those added by type extenders.
 *
 */
export function GetTypePropsWithExtensions(type: Type, value?: any) {
    const hasExtenders = HasTypeExtenders(type)

    if (!hasExtenders && value !== undefined) {
        // If there are no type extenders, or no value that can affect the type extenders
        // it is viable to return the result from cache
        const cached = extendedPropsCache.get(type)
        if (cached) return cached
    }

    const typeName = GetTypeAlias(type)

    let props = GetTypeProps(type, value)

    if (value === undefined) {
        const modelName = GetTypeModel(type)
        const extensions = typeName ? TypeExtender.findStatic(modelName, typeName) : undefined

        if (extensions?.length) {
            const extendedProps = extensions?.flatMap((e) => FieldsToProps(e.fields)) ?? []
            props = [...props, ...extendedProps]
        }
        // Static extensions, not specific to the value can be cached
        extendedPropsCache.set(type, props)
        return props
    } else {
        // Looking for extensions specific to the value
        const extensions = GetTypeExtensions(type, value, {})
        if (extensions.length) {
            const extendedProps = extensions.flatMap((e) => FieldsToProps(e.fields))
            props = [...props, ...extendedProps]
        }
        if (value === undefined) {
            // If the value is undefined, we can cache the result. Otherwise,
            // the result is specific to the value and cannot be cached.
            extendedPropsCache.set(type, props)
        }

        return props
    }
}

/**
 * Returns a union of all the possible extension types of the given type.
 *
 * If the type has no extension, the type itself is returned.
 */
export function GetTypeExtendedUnion(type: Type, value?: any, doc?: any) {
    const extensions = GetTypeExtensions(type, value, doc)
    if (extensions.length === 0) return type

    const union: Type[] = [type]
    for (const ext of extensions) {
        if (ext.alias) union.push(GetType(ext.alias))
        else {
            const schema: Type = {
                alias: ext.alias,
                tags: ext.tags,
                props: FieldsToProps(ext.fields),
            }
            union.push(schema)
        }
    }
    return { union }
}

/*
 * Find and auto-register type extenders defined with the @extension attribute.
 */
export function RegisterTypeExtenders() {
    const extensions = GetReflectionInfo().types.filter((t) => {
        if (!IsIntersectionType(t)) return false
        if (!t.tags?.extension) return false

        const [col, ext] = t.intersection

        if (typeof col !== "object" || typeof ext !== "object") return false
        if (!col.alias) return false

        return true
    }) as IntersectionType[]
    for (const extension of extensions) {
        const baseType = extension.intersection[0]
        const baseAlias =
            typeof baseType === "object" && "alias" in baseType ? baseType.alias : undefined
        if (!baseAlias) {
            throw new Error(
                "@extension type must be an intersection of an aliased collection type and an object type"
            )
        }
        const alias = extension.alias
        if (!alias) {
            throw new Error("@extension type must have an alias")
        }
        // When inheriting through more than one layer, we only want to inherit
        // the last type in the chain. E.g. if B extends A, and C extends B, the
        // intersection chain will be [A, B, C], and we want to use the props from C.
        const ext = extension.intersection[extension.intersection.length - 1]
        if (typeof ext !== "object" || !("props" in ext)) {
            throw new Error("@extension type must be an object type with plain properties")
        }

        const typeExtension: TypeExtension = {
            title: prettyCamel(alias ?? "Item"),
            alias,
            tags: ext.tags,
            fields: ext.props.map(
                (p): FieldSchema => ({
                    name: p.name,
                    schema: { type: p.type },
                    description: p.description?.en ?? "",
                    required: !p.optional,
                    tags: p.tags,
                })
            ),
        }

        const predicateName = extension.tags?.extension
        const predicate =
            typeof predicateName === "string"
                ? TypeExtender.predicates.find((p) => p.name === predicateName)
                : undefined

        addTypeExtender(baseAlias, (obj, doc) => {
            if (!predicate || predicate(obj, doc)) return typeExtension
        })

        const modelName = GetTypeModel(baseType)

        let staticExts = TypeExtender.findStatic(modelName, baseAlias)
        if (!staticExts) {
            staticExts = []
            staticTypeExtenders.set(modelName ? `${modelName}:${baseAlias}` : baseAlias, staticExts)
        }
        staticExts.push(typeExtension)
    }
}
