import { CSSProperties, useState } from "react"
import {
    deleteEditingTemplate,
    postEditingTemplate,
    useEditingTemplates,
} from "../../studio/client"
import { lowercaseFirstLetter } from "../../reactor/Helpers"
import { RButton } from "../../studio/Views/Buttons"
import { SectionView } from "./EditableSections"
import { ContextModal } from "../modal/Modal"
import { useHover } from "../hooks/useHover"
import { Section } from "./Section"
import { ErrorBoundary } from "react-error-boundary"
import { EmailSectionView } from "../email/EmailSections"
import { EmailSection } from "../email/EmailSection"

export async function PickEditingTemplate(
    type: string,
    showModal: ContextModal<any>
): Promise<Section | EmailSection | undefined> {
    return await showModal((close) => <EditingTemplatePicker type={type} onClose={close} />)
}

export async function SaveAsEditingTemplate(type: string, obj: any, showModal: ContextModal<any>) {
    await showModal((close) => (
        <EditingTemplateSaveAsDialog type={type} obj={obj} close={() => close(undefined)} />
    ))
}

function EditingTemplatePicker(props: { type: string; onClose: (result: any) => void }) {
    const { data: templates, refresh } = useEditingTemplates(props.type)

    return (
        <div
            style={{
                backgroundColor: "white",
                padding: 32,
                zIndex: 1000,
                width: 1350,
                height: 720,
                maxWidth: "95%",
                maxHeight: "95%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <h4>Select template</h4>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflowY: "scroll",
                }}
            >
                {templates?.templates?.length ? (
                    <h6 style={{ marginTop: 16 }}>Saved</h6>
                ) : undefined}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    {templates?.templates.map((t) => (
                        <div
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onClose(t.content)
                            }}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                margin: 16,
                            }}
                        >
                            <TemplatePreview
                                type={props.type}
                                obj={t.content}
                                zoom={0.2}
                                hoverStyle={{
                                    transform: "scale(1.05)",
                                    border: "3px solid #996",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <div style={{ flex: 1 }}>{t.name}</div>
                                <RButton
                                    icon="trash"
                                    style={{ zoom: 0.7 }}
                                    onClick={async () => {
                                        if (
                                            confirm(
                                                `Are you sure you want to delete the template named ${t.name}`
                                            )
                                        ) {
                                            await deleteEditingTemplate(props.type, t.id)
                                            await refresh()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {templates?.templates?.length ? (
                    <h6 style={{ marginTop: 16 }}>Blank</h6>
                ) : undefined}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    {templates?.defaults.map((t) => (
                        <div
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onClose(t.content)
                            }}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                margin: 16,
                            }}
                        >
                            <TemplatePreview
                                type={props.type}
                                obj={t.content}
                                zoom={0.2}
                                innerZoom={t.zoom}
                                hoverStyle={{
                                    transform: "scale(1.05)",
                                    border: "3px solid #996",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                {t.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <RButton onClick={() => props.onClose(undefined)}>Close</RButton>
            </div>
        </div>
    )
}

function EditingTemplateSaveAsDialog({
    type,
    obj,
    close,
}: {
    type: string
    obj: any
    close: () => void
}) {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    async function save() {
        try {
            await postEditingTemplate(type, { name, description, content: obj })
            close()
        } catch (e: any) {
            alert("Error: " + e.detail)
        }
    }

    return (
        <div style={{ backgroundColor: "white", padding: 32 }}>
            <TemplatePreview obj={obj} type={type} />
            <h3>Save as {lowercaseFirstLetter(type)} template</h3>
            <div className="form" style={{ display: "flex", flexDirection: "column" }}>
                <div className="form-group">
                    <div className="form-text">Name:</div>
                    <input
                        autoFocus={true}
                        className="form-control"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <div className="form-text">Description (optional):</div>
                    <input
                        className="form-control"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
                <RButton onClick={close}>Cancel</RButton>
                <RButton variant="primary" onClick={save}>
                    Save
                </RButton>
            </div>
        </div>
    )
}

function TemplatePreview(props: {
    obj: any
    zoom?: number
    hoverStyle?: CSSProperties
    innerZoom?: number
    type: string
}) {
    const { hover, hoverProps } = useHover()

    return (
        <div
            {...hoverProps}
            style={{
                width: 1280,
                height: 720,
                zoom: props.zoom ?? 0.4,
                border: "1px solid #ddd",
                boxShadow: "5px 5px 10px #ddd",
                marginBottom: 32,
                overflow: "clip",
                transition: "all 0.25s ease",
                ...(hover ? props.hoverStyle : {}),
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: "none",
                    zoom: props.innerZoom ?? 1,
                }}
            >
                <ErrorBoundary fallback={<div style={{ fontSize: 28 }}>❌ Rendering error</div>}>
                    {props.type === "Section" ? (
                        <SectionView section={props.obj} index={0} totalCount={1} />
                    ) : undefined}
                    {props.type === "EmailSection" ? (
                        <EmailSectionView section={props.obj} />
                    ) : undefined}
                </ErrorBoundary>
            </div>
        </div>
    )
}
