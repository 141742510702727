import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { ShadowRootContext } from "./ShadowRoot"

export const ProximityContext = createContext<{
    addProximityBox: (div: HTMLDivElement) => void
    removeProximityBox: (div: HTMLElement) => void
    closest: HTMLDivElement | null
} | null>(null)

export function ProximityContextProvider({ children }: { children: React.ReactNode }) {
    const [boxes, setBoxes] = useState<HTMLDivElement[]>([])
    const [closest, setClosest] = useState<HTMLDivElement | null>(null)

    const addProximityBox = useCallback((div: HTMLDivElement) => {
        setBoxes((prev) => [...prev, div])
    }, [])

    const removeProximityBox = useCallback((div: HTMLElement) => {
        setBoxes((prev) => prev.filter((box) => box !== div))
    }, [])

    const shadowRoot = useContext(ShadowRootContext)

    useEffect(() => {
        if (!shadowRoot) return

        const handleMouseMove = (e: MouseEvent) => {
            let closestDist: number | null = null
            let closestBox: HTMLDivElement | null = null

            for (const box of boxes) {
                const rect = box.getBoundingClientRect()

                // Calculate horizontal and vertical distances to edges
                const dx = Math.max(rect.left - e.clientX, 0, e.clientX - rect.right)
                const dy = Math.max(rect.top - e.clientY, 0, e.clientY - rect.bottom)

                // Distance to the box interior (0 if mouse is inside the box)
                const distance = Math.sqrt(dx * dx + dy * dy)

                if (closestDist === null || distance < closestDist) {
                    closestDist = distance
                    closestBox = box
                }
            }

            if (closestDist !== null && closestDist < 20) {
                setClosest(closestBox)
            } else {
                setClosest(null)
            }
        }

        shadowRoot.addEventListener("mousemove", handleMouseMove)
        return () => shadowRoot.removeEventListener("mousemove", handleMouseMove)
    }, [boxes, shadowRoot])

    const context = useMemo(
        () => ({ addProximityBox, removeProximityBox, closest }),
        [addProximityBox, removeProximityBox, closest]
    )

    return <ProximityContext.Provider value={context}>{children}</ProximityContext.Provider>
}

export function useProximityBox() {
    const boxRef = useRef<HTMLDivElement>(null)
    const pc = useContext(ProximityContext)

    useEffect(() => {
        if (!pc) return

        const box = boxRef.current
        if (!box) return

        pc.addProximityBox(box)
        return () => pc.removeProximityBox(box)
    }, [pc])

    const isClosest = boxRef.current && pc?.closest === boxRef.current

    return {
        /**
         * The ref that should be attached to the box element.
         */
        boxRef,
        /**
         * Whether this box is the closest to the mouse in the current ProximityContext.
         */
        isClosest,
    }
}
