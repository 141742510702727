import { createContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

/**
 * A context that provides the host element of the shadow root portal.
 */
export const ShadowRootContext = createContext<HTMLDivElement | null>(null)

export function ShadowRoot({ children, cssCode }: { children: React.ReactNode; cssCode: string }) {
    const hostRef = useRef<HTMLDivElement>(null)
    const [shadow, setShadow] = useState<ShadowRoot | null>(null)

    useEffect(() => {
        if (!shadow && hostRef.current) {
            setShadow(hostRef.current.attachShadow({ mode: "open" }))
        }
    }, [shadow])

    return (
        <ShadowRootContext.Provider value={hostRef.current}>
            <div ref={hostRef}>
                {shadow &&
                    createPortal(
                        <>
                            <style>{cssCode}</style>
                            {children}
                        </>,
                        shadow
                    )}
            </div>
        </ShadowRootContext.Provider>
    )
}
